import React  from 'react';
import DayPicker from './DatePicker';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { logger } from '../common/Logger';
import { Stack } from '@mui/material';

//All date must be already in dayjs format
const calculatorHelperValue = (refDate, currentDate) => {

    const curMnt = currentDate.diff(refDate,'month')
    const helperDefValue = refDate.date() === currentDate.date() && [1, 3, 6, 12, 36].includes(curMnt) ? curMnt : ''
    logger.trace("refDate.day() : ", refDate.date())
    logger.trace("currentDate.day() : ", currentDate.date())
    logger.trace("curMnt : ", curMnt)
    logger.trace("helperDefValue : ", helperDefValue)
    return helperDefValue
}

function DayHelpPicker({referenceValue, value, inputChange, label, required, minDate, maxDate}) {    
    const refDate = dayjs(referenceValue)
    const [helperValue, setHelperValue] = useState(calculatorHelperValue(refDate, value));

    const helperChange = (event) => {
        setHelperValue(event.target.value)
        const newDate = refDate.add(event.target.value, 'month')
        logger.debug("helperChange -> New date: ", newDate)
        inputChange(newDate)
    }

    const dayChange = (event) => {
        logger.debug("dayChange -> New date: ", event)
        setHelperValue(calculatorHelperValue(refDate, event))
        inputChange(event)
    }

    //TODO : le shirk ne fonctionne pas
    return (
        <Stack direction="row" spacing={2}>
        <FormControl>
            <InputLabel id="delaisInputLabel">Délais</InputLabel>
            <Select 
                slotProps={{
                    inputLabel: { 
                        shrink: true 
                    }
                }}
                variant='standard'
                sx={{ minWidth: 75, mx: 1 }}
                data-testid="selDelay"
                labelId="delaisInputLabel"
                id="delaisInput"
                value={helperValue}
                label="Délais"
                onChange={helperChange}
            >
                <MenuItem value={1}>1 mois</MenuItem>
                <MenuItem value={3}>3 mois</MenuItem>
                <MenuItem value={6}>6 mois</MenuItem>
                <MenuItem value={12}>1 ans</MenuItem>
                <MenuItem value={36}>3 ans</MenuItem>
            </Select>
        </FormControl>
        <DayPicker 
            value={value}
            inputChange={dayChange}
            label={label}
            required={required}
            minDate={minDate}
            maxDate={maxDate}
        />
        </Stack>
    )
}


export default DayHelpPicker