import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import { useLocation} from 'react-router-dom';
import logo from '../img/logo-nb.png'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AnimalIconContent, ClientIconContent, ConsultationIconContent, EventConfirmIconContent , EventRequestIconContent, FactureIconContent, LayoutIconContent, ManagmentIconContent, PrescriptionIconContent, ProductIconContent, VaccinIconContent, Vue360IconContent} from './common/IconFactory';
import { useTranslation } from 'react-i18next';
import { hasModuleBilling, hasModuleCieSetting, hasModuleConsultation, hasModuleEventConfirm, hasModuleInccomingEvent, hasModuleProduct, hasModuleRx, hasModuleVaccine } from '../services/AccessHub';
import { logger } from './common/Logger';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';

//Event navigation
export const eventsRequestUrl = "/eventsRequest";
export const eventsConfirmUrl = "/eventsConfirm";
//Client navigation
export const clientUrl = "/clients";
export const animalUrl = "/animals";
export const billUrl = "/factures";
export const view360Url = "/vue360";
//Medic navigation
export const consultationUrl = "/consultations";
export const rxUrl = "/prescriptions";
export const vaccineUrl = "/vaccins";

//Other navigation
export const profileUrl = "/profile";
export const accountUrl = "/account";

//Managment navigation
export const layoutUrl = "/layoutManagment";
export const overviewUrl = "/overview";
export const productUrl = "/products";

//Session navigation
export const loginUrl = "/login";
export const logoutUrl = "/logout";
export const resetPwsUrl = "/resetPassword";
export const changePwsUrl = "/changePassword";
export const signupUrl = "/signup";
export const signupCompleteUrl = "/signupComplete";

export const GetRequiredView = () => {
  const location = useLocation();
  const askView = location.state?.view

  return askView ? askView : "view"
}




const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { t } = useTranslation();
  const { ...other } = props;

  const [clientOpen, setClientOpen] = React.useState(true);
  const [medicOpen, setMedicOpen] = React.useState(true);
  const [managmentOpen, setManagmentOpen] = React.useState(false);

  const handleManagmentClick = () => {
    setManagmentOpen(!managmentOpen);
  };
  const handleMedicClick = () => {
    setMedicOpen(!medicOpen);
  };
  const handleClientClick = () => {
    setClientOpen(!clientOpen);
  };

  const location = useLocation();
  let navigate = useNavigate(); 

  let categories = [
    {
      id: t('navigation.eventSection'),
      icon: <CalendarMonthIcon/>,
      access: hasModuleInccomingEvent() || hasModuleEventConfirm(),
      children: [
        { testId: 'navEventPage',id: t('navigation.eventPage'), access: hasModuleInccomingEvent() , nav: eventsRequestUrl, icon: <EventRequestIconContent/> , active: location.pathname === eventsRequestUrl},
        { testId: 'navEventConfirm',id: t('navigation.eventConfirmPage'), access: hasModuleEventConfirm() ,nav: eventsConfirmUrl, icon:  <EventConfirmIconContent/> , active: location.pathname === eventsConfirmUrl},
      ],
    },
    {
      id: t('navigation.clientSection'),
      icon: <ContactPageIcon color='primary'/>,
      collapseIcon: clientOpen ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />,
      collapseClick: handleClientClick,
      isCollapse: clientOpen,
      access: true,
      children: [
        { testId: 'navClient',id: t('client.title'), access: true, nav: clientUrl, icon: <ClientIconContent/>, active: location.pathname === clientUrl},
        { testId: 'navAnimal',id: t('animal.title'), access: true, nav: animalUrl, icon: <AnimalIconContent/>, active: location.pathname === animalUrl },
        { testId: 'navBilling',id: t('billing.title'), access: hasModuleBilling(), nav: billUrl, icon: <FactureIconContent/>, active: location.pathname === billUrl },
        { testId: 'nav360',id: 'Vue 360', access: true, nav: view360Url, icon: <Vue360IconContent/>, active: location.pathname === view360Url },
      ],
    },
    {
      id: t('navigation.medicSection'),
      icon: <MedicalInformationIcon color='primary' />,
      collapseIcon: medicOpen ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />,
      collapseClick: handleMedicClick,
      isCollapse: medicOpen,
      access: hasModuleConsultation() || hasModuleRx() || hasModuleVaccine(),
      children: [
        { testId: 'navConsultation', id: t('consultation.title'), access: hasModuleConsultation(), nav: consultationUrl, icon: <ConsultationIconContent/>, active: location.pathname === consultationUrl},
        { testId: 'navRx',id: t('rx.title'), access: hasModuleRx(), nav: rxUrl, icon: <PrescriptionIconContent/>, active: location.pathname === rxUrl},
        { testId: 'navVaccine',id: t('vaccine.title'), access: hasModuleVaccine(), nav: vaccineUrl, icon: <VaccinIconContent/> , active: location.pathname === vaccineUrl}
      ],
    },
    {
      id: t('navigation.managmentSection'),
      icon: <ManagmentIconContent color='primary' />,
      collapseIcon: managmentOpen ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />,
      collapseClick: handleManagmentClick,
      isCollapse: managmentOpen,
      access: hasModuleCieSetting() || hasModuleProduct(),
      children: [
        { testId: 'navLayout', id: t('navigation.layoutSection'), access: hasModuleCieSetting(), nav: layoutUrl, icon: <LayoutIconContent/>, active: location.pathname === layoutUrl},
        { testId: 'navProduct',id: t('navigation.productSection'), access: hasModuleProduct(), nav: productUrl, icon: <ProductIconContent/>, active: location.pathname === productUrl}],
    }
  ];



  function itemClick(destination) {
    logger.debug("Nav to : " + destination)
    navigate(destination)
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List key="main-list" disablePadding>
        <ListItem key="icon-logo" sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff', width: '255' }}>
          <img src={logo} className="App-NavLogo" alt="logo" />
        </ListItem>

        {categories.map(({ testId, id, access, children, icon, collapseIcon, collapseClick, isCollapse }) => (

          <>
          { (access) &&
            <Box key={id} sx={{ bgcolor: '#101F33' }}>
              <ListItem key={id} sx={{ py: 2, px: 3 }} onClick={collapseClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText data-testid={testId} sx={{ color: '#fff' }}>{id}</ListItemText>
                {collapseIcon}
              </ListItem>
              {children.map(({ testId, id: childId, access, icon, active, nav }) => (
                <>
                  {
                    (access) &&
                  <Collapse in={isCollapse || isCollapse === undefined} timeout="auto" unmountOnExit>
                    <ListItem disablePadding key={childId}>
                        <ListItemButton selected={active} sx={item}>
                          <ListItemIcon onClick={function() {itemClick(nav)}} >{icon}</ListItemIcon>
                          <ListItemText data-testid={testId} onClick={function() {itemClick(nav)}}>{childId}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                  </Collapse>
                  }
                </>
              ))}
              <Divider sx={{ mt: 2 }} />
            </Box>
          }
          </>
        ))}
      </List>
    </Drawer>
  );
}


/*
  <ListItem key="overview-list" sx={{ ...item, ...itemCategory }} >
            <ListItemButton selected={location.pathname === overviewUrl} sx={item} >
              <ListItemIcon onClick={function() {itemClick(overviewUrl)}}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText onClick={function() {itemClick(overviewUrl)}}>Vue d&#39;ensemble</ListItemText>
            </ListItemButton>
          </ListItem>
*/