import * as React from 'react';

import { setAnimal, setClient } from '../common/ClientSlice';
import { useDispatch, useSelector } from 'react-redux';
import AnimalSelector from '../animals/AnimalSelector';
import ClientSelector from '../clients/ClientSelector';
import Top from './Top';


function ClientHeader({handleDrawerToggle}) {
    //Display section
    const showAnimalsSelector = useSelector(state => state.client.showAnimalsSelector).payload;
    const showClearAnimalsSelector = useSelector(state => state.client.showClearAnimalsSelector).payload;
    const showClientsSelector = useSelector(state => state.client.showClientsSelector).payload;
    const allowSelection = useSelector(state => state.client.allowSelection).payload;

    //Data section
    const client = useSelector(state => state.client.client);
    const animal = useSelector(state => state.client.animal);
    const clientNo = client?.payload ? client.payload.no : null
    const clientKey = client?.payload ? client.payload.key : null
    const animalKey = animal?.payload ? animal.payload.key : null
    const dispatch = useDispatch()

    return (
    <Top onDrawerToggle={handleDrawerToggle} >
        <>
        { showClientsSelector !== false && 
            <ClientSelector 
                autoSelect={true}
                disable={!allowSelection}
                clientKey={clientKey}
                setClient={(clientData) => dispatch(setClient(clientData))}
                loadClient={true}
                />
        }

        {showAnimalsSelector && 
            <AnimalSelector
                autoSelect={true}
                loadAnimal={true}
                disable={!allowSelection}
                clientNo={clientNo}s
                animal={animalKey}
                allowClearable={showClearAnimalsSelector}
                setAnimal={(animalData) => dispatch(setAnimal(animalData))} />
        }
      </>

    </Top>)
}


export default ClientHeader