
import React, { useEffect }  from 'react';
import { useState } from "react"
import TabSelector from "../../layout/TabSelector"
import { CieHeaderView } from './CieHeaderView';
import { useTranslation } from 'react-i18next';
import { CiePreferenceView } from './CiePreferenceView';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../../services/authenticate';
import { PlanView } from './PlanView';
import { PlanBuy } from './PlanBuy';
import { TaxView } from './TaxView';
import { useDispatch } from 'react-redux';
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../../common/ClientSlice';
import { hasModuleTax } from '../../../services/AccessHub';


const CieManager = () => {
    const {t} = useTranslation()
    const [currentView, setCurrentView] = useState("cie")
    

    const defaultView = [{key: 0, title: t('header.description'), name: "cie"},
        {key: 1, title: t('header.config.title'), name: "pref"},
        {key: 2, title: t('header.plan.title'), name: "plan"},
        {key: 3, title: t('header.plan.buy'), name: "buy"}
        ]

    // eslint-disable-next-line
    const [availViews, setAvailViews] = useState(hasModuleTax() ? [...defaultView, {key: 4, title: t('header.tax.title'), name: "tax"}] : defaultView)

    const dispatch = useDispatch()
    //Setup client header
    dispatch(setShowClearAnimalsSelector(false)) 
    dispatch(setShowClientsSelector(false))
    dispatch(setShowAnimalsSelector(false))
    dispatch(setAllowSelection(false))
    
    useEffect(() => {
        ReactGA.event("page_view", {"page_location": "header", "client_id": GetClientKey(), "page_title": "header_" + currentView});
    }, [currentView]);

      
    return (
        <>
            <TabSelector 
                onViewChange={setCurrentView}
                pageTitle={t('header.title')}
                availableView={availViews}
                currentView={currentView}
                />
            { currentView === "cie" && <CieHeaderView /> }
            { currentView === "pref" && <CiePreferenceView />}
            { currentView === "plan" && <PlanView/> }
            { currentView === "buy" && <PlanBuy/> }
            { currentView === "tax" && <TaxView/> }
        </>
        );
}


export default CieManager

