import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import Animals from "../../api/Animals"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setRefreshAnimal } from '../common/ClientSlice';
import { messageStore } from '../common/InfoMessage';
import { alphaSortOptionTitreNoKey, selectOptionTitreNoKey } from '../common/SelectorUtils';
import ReactGA from "react-ga4";
import { logger } from '../common/Logger';

const AnimalSelector = ({clientNo, animal, setAnimal, disable, loadAnimal, allowClearable, autoSelect}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const refreshAnimal = useSelector(state => state.client.refreshAnimal);
  const [clientNoLoad, setClientNoLoad] = useState(null)
  const [optionsAnimals, setOptionsAnimals] = useState([])

  useEffect(() => {
    if (refreshAnimal.payload === true) {
      setClientNoLoad(null)
      setOptionsAnimals([])
      logger.debug("refreshAnimal : setClientNoLoad to null")
    }
    if (clientNo && clientNo !== clientNoLoad) {
      dispatch(addLoadingCount())
      logger.debug("refreshAnimal : clientNo : ", clientNo)
      new Animals().List(clientNo)
        .then((allAnimals) => {
          logger.debug("allAnimals : ", allAnimals)
          const optAnimals = alphaSortOptionTitreNoKey(allAnimals.filter(a => a.substring(0, a.indexOf("/")) === clientNo.toString()))

          logger.debug("optionsAnimals : ", optAnimals)
          setOptionsAnimals(optAnimals)
          setClientNoLoad(clientNo)
          dispatch(setRefreshAnimal(false))

          if (autoSelect && optAnimals.length > 0 && (animal === undefined || animal === null)) {
            internalOnChange(null, optAnimals[0])
          }

        })
        .catch((error) => {messageStore.sendMessage(error.message, "error")})
        .finally(()=>dispatch(subLoadingCount()))
    
      }  
    }, [clientNo, refreshAnimal, clientNoLoad]);



    const internalOnChange = (event, newKey) => {
      ReactGA.event("select_item", {"item_list_name": "client", "items": [{"item_id" : newKey, "item_name" : selectOptionTitreNoKey(newKey), "item_category": clientNo }] });

      if (loadAnimal) {
        dispatch(addLoadingCount())
        new Animals().Get(newKey)
          .then(setAnimal)
          .finally(()=>dispatch(subLoadingCount()))
      } else {
        setAnimal(newKey)
      }
    }


    return (<Autocomplete
      aria-label="lstAnimals"
      disabled={disable}
      size='small'
      disableClearable={allowClearable ? false : true}
      onChange={internalOnChange}
      sx={{ maxWidth: 300, width: '100%' }}
      value={animal === undefined ? null : animal}
      options={optionsAnimals}
      getOptionLabel={selectOptionTitreNoKey}
      renderInput={(params) => 
        <TextField {...params} 
          label={t('animal.title')}/>
      }
    />)
  }
  

  AnimalSelector.propTypes = {
    clientNo: PropTypes.number,
    animal: PropTypes.string,
    loadAnimal: PropTypes.bool,
    setAnimal: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };
  
  export default AnimalSelector