import React from "react"
import { AnimalIconContent, ConsultationIconContent, PrescriptionIconContent, VaccinIconContent } from "../common/IconFactory"
import ComponentCard from "./ComponentCard"
import { sexeMap } from "../animals/BaseAnimal"
import { animalsLogo } from "../animals/AnimalLogo"
import { animalUrl, consultationUrl, rxUrl, vaccineUrl } from "../Navigator"
import { navigateTo } from "./ViewHelper"
import { IconButton } from "@mui/material"
import { setAnimal } from "../common/ClientSlice"

const AnimalCard = (navigateAction, dispatch, animal) => {

  const navTo = (url, itemNo) => {
    dispatch(setAnimal(animal))
    return navigateTo(navigateAction, url, 
      {
        view: "view",
        itemNoToView: itemNo
      }
    )
  }

  const vaccins = ComponentCard(navTo, vaccineUrl, "Vaccin", animal.vaccines, <VaccinIconContent/>)
  const rxs = ComponentCard(navTo, rxUrl, "Rx", animal.rxs, <PrescriptionIconContent/>)
  const consultations = ComponentCard(navTo, consultationUrl, "Consultation", animal.consultations, <ConsultationIconContent />)

  const children = [vaccins, rxs, consultations].filter(n => n)



    return {
        title: <IconButton onClick={() => navTo(animalUrl)}><AnimalIconContent /> {animal.name}</IconButton>,
        member: [{
        name: animal.name,
        add: animal.race + " - " + sexeMap[animal.sexe],
        image_url: animalsLogo[animal.type]
        }],
        children: children.length === 0 ? null : children
    }
}

export const constructAnimalsCard = (navigateAction, dispatch, animals) => {
    var data = []
    for (const animal of animals) {
      data.push(AnimalCard(navigateAction, dispatch, animal))
    }
    return data
  }

export default AnimalCard