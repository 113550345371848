import React from 'react';
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import {StandardTextField} from '../../input/TextFieldStyle';
import PhoneTextField from '../../input/PhoneTextField';
import EmailTextField from '../../input/EmailTextField';
const emptyProfile = {"sub": "", "clientKey": "", "subClientKey": null, "email": "", "emailVerified": false, "phone": "", "phoneVerified": false, "username": "" }

const VerifiedIcon = ({isEditMode, isVerified}) => {
    const { t } = useTranslation();
    if (isEditMode) {
        return (<></>)
    }
    return (
        <Tooltip title={t(isVerified  === "true" ? "account.pofile.verify" : "account.pofile.notVerify")}>
        {isVerified === "true" ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />}
        </Tooltip>
    )
}


const MainProfileSection = ({item, handleInputChange, isEditMode}) => {
    const { t } = useTranslation();
    const variant = 'standard'
    return (
        <>
        {!isEditMode &&  
            <>
            <StandardTextField label={t("account.pofile.key")}
                variant={variant}
                fullWidth
                onChange={(e) => handleInputChange('sub', e.target.value)}
                disabled={!isEditMode}
                value={item.sub} />
            <StandardTextField label={t("account.pofile.cieKey")}
                variant={variant}
                fullWidth
                disabled={!isEditMode}
                value={item.clientKey} />
            </>
        }
        <StandardTextField label={t("session.username")}
            variant={variant}
            fullWidth
            minLength={6}
            maxLength={50}
            onChange={(e) => handleInputChange('username', e.target.value)}
            disabled={!isEditMode}
            value={item.username} />
        <Box sx={{display: 'inline-flex', width: '100%' }}>
            <EmailTextField label={t("account.pofile.email")}
            variant={variant}
            fullWidth
            required
            onChange={(e) => handleInputChange('email', e.target.value)}
            disabled={!isEditMode}
            value={item.email} />
            <VerifiedIcon isEditMode={isEditMode} isVerified={item.emailVerified} />
        </Box>  
        <br/>
        <Box sx={{display: 'inline-flex', width: '100%' }}>
        <PhoneTextField label={t("account.pofile.phone")}
            variant={variant}
            required
            onChange={(e) => handleInputChange('phone', e.target.value)}
            disabled={!isEditMode}
            value={item.phone} 
            />
            <VerifiedIcon isEditMode={isEditMode} isVerified={item.phoneVerified} />
        </Box>

        </>
    )
}



export {MainProfileSection, emptyProfile}