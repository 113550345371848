import { FactureIconContent } from "../common/IconFactory"
import React from "react"
import { logger } from '../common/Logger';
import { IconButton } from "@mui/material";
import { billUrl } from "../Navigator";
import { navigateTo } from "./ViewHelper"

const BillCard = (onItemClick, navUrl, title, items) => {
    var childrens = []
    if (items && items.length > 0) {
      for (const childs of items) {
        childrens.push({
          name: <IconButton size='small' onClick={() => onItemClick(navUrl, childs.no)}>{"#" + childs.no + "  " + childs.date}</IconButton>,
          add: childs.total + "$",
        })
      }
      
  
      return {
        title: title,
        member: childrens
      }
    }
    return null
}

export const constructBillSection = (navigateAction, client) => {
  const navTo = (url, itemNo) => {
    return navigateTo(navigateAction, url, 
      {
        view: "view",
        itemNoToView: itemNo
      }
    )
  }


  const billNotPaid = BillCard(navTo, billUrl, "Non payé", client.bills?.filter(b => !b.isPaid))
  const billPaid = BillCard(navTo, billUrl, "Payé", client.bills?.filter(b => b.isPaid))
  const mergedBills = [billPaid, billNotPaid].filter(n => n)
  logger.debug("mergedBills : ", mergedBills)
  var billSection = null
  if (mergedBills.length > 0) {
    billSection = {
        title: <><FactureIconContent />Facture</>,
        children: mergedBills
      }
  }
  return billSection
}


export default BillCard