import React from 'react'
import { Box, Paper, Typography } from '@mui/material';
import PlanCard from './plan/PlanCard';
import { LoadActionsLimit } from '../../headers/ActionsLimitLoader';
import { MyPlanActionList, MyPlanModuleList } from './plan/PlanCardList';
import { useSelector } from 'react-redux';

export function PlanView() {
  const pdfContentRef = React.createRef();
  const curLimits = useSelector(state => state.common.limits)

  const getView =() => {
    const planModuleList = MyPlanModuleList
    const planActionList = MyPlanActionList(curLimits?.payload)


    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
        <LoadActionsLimit />

        <div ref={pdfContentRef} id="toByPrint" border="1">
          <Typography sx={{ my: 3, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            Module
          </Typography>
          <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
            { planModuleList.map(item => 
              <PlanCard key={item.title} 
                title={item.title} 
                description={item.description} 
                logo={item.logo} 
                disabled={true}
                shouldBeDisplay={item.haveIt} 
                haveIt={item.haveIt}  />)}
            </Box>
          
          <Typography sx={{ my: 3, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            Action
          </Typography>
          <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
            { planActionList.map(item => 
              <PlanCard key={item.title} 
                title={item.title} 
                description={item.description}
                number={item.nb}
                disabled={true}
                logo={item.logo} 
                shouldBeDisplay={item.haveIt} 
                haveIt={item.haveIt}  />)}
            </Box> 
          </div>
        </Paper>
      )
  }

    return getView()
}

