import Clients from "../../api/Clients"
import { setClient } from "../common/ClientSlice"
import { messageStore } from "../common/InfoMessage"
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice"

export const updateClient = (clientUpdate, updateDate, sucessSaveMessage, errorSaveMessage, dispatch) => { 
    dispatch(addLoadingCount())
    return new Clients().Update(clientUpdate, updateDate).then((result)=> {
      if (result.status === 200) {
        new Clients().Get(clientUpdate.key).then(setClient).then(dispatch)
        messageStore.sendMessage(sucessSaveMessage)
      } else {
        messageStore.sendMessage(result.statusText, "error", errorSaveMessage)
      }
    })
    .then(() => dispatch(subLoadingCount()))
}