import React, {useState }  from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

let messageInfo = {}
let messageSubscribers = new Set();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const messageStore = {
  sendMessage(message, severity = 'success', title = null) {
    messageInfo = {}
    messageInfo.message = message
    messageInfo.severity = severity
    messageInfo.title = title

    messageSubscribers.forEach((sub) => sub(messageInfo));

  },
  getMessageInfo() {
    return messageInfo
  },
  // Subscribe method adds the "callback" to the "subscribers" set, and
  // return a method to unsubscribe from the store.
  subscribe(callback) {
    messageSubscribers.add(callback);
    return () => messageSubscribers.delete(callback);
  },
};

function InfoMessage() {

  const [messageOpen, setMessageOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [messageText, setMessageText] = useState("");

  const [messageSeverity, setMessageSeverity] = useState('success');



  const newMessageArrived = (message) => {
    handleMessageClick(message.message, message.severity, message.title)
  }

  const handleMessageClick = (message, severity = 'success', title = null) => {
    setMessageTitle(title)
    setMessageText(message)
    setMessageSeverity(severity)
    setMessageOpen(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageOpen(false);
  };

  messageStore.subscribe(newMessageArrived)


  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={messageOpen} autoHideDuration={3500} onClose={handleMessageClose}>
        <Alert  onClose={handleMessageClose} severity={messageSeverity} sx={{ width: '100%' }}>
          <b>{messageTitle}&nbsp;</b>
          {messageText}
        </Alert>
      </Snackbar>
    </Stack>

  ) 
}

export default InfoMessage
export {messageStore}