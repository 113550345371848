import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import EditProductDialog from './EditProductDialog';
import { AddItemIconContent } from '../../common/IconFactory';
import { useTranslation } from 'react-i18next';
import Products from '../../../api/Products';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from '../../common/CommonSlice';
import { addLoadingCount, subLoadingCount } from '../../common/LoadingSlice';
import { emptyItem, emptyItemDetail, ProductView } from './BaseProduct';
import { messageStore } from '../../common/InfoMessage';


const ViewProducts = () => {
  const [t] = useTranslation();
  const productsList = useSelector(state => state.common.products)?.payload
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedDetail, setSelectedDetail] = useState({});
  const [isUpdate, setIsUpdate] = useState(null);
  const dispatch = useDispatch()

  const handleEditClick = (product, detail) => {
      setSelectedProduct(product);
      setSelectedDetail(detail);
      setIsUpdate(true)
      setOpenDialog(true);
    };

  const createProduct = () => {
      setSelectedProduct(emptyItem);
      setSelectedDetail(emptyItemDetail);
      setIsUpdate(false)
      setOpenDialog(true);
  }

    const handleDelete = (productNo) => {
      dispatch(addLoadingCount())
      const pApi = new Products()
      let productsListUpdate = JSON.parse(JSON.stringify(productsList))
        const index = productsListUpdate.findIndex(obj => obj.no === productNo)
        delete productsListUpdate[index]
        productsListUpdate = productsListUpdate.filter(n => n)
      
      return pApi.DeleteDetail(productNo)
        .then((result) => {
          if (result.status === 200) {
            return pApi.Update(productsListUpdate)
          } else {
            messageStore.sendMessage(result.statusText, "error", t('message.deleteError'))
            return result
          }
        })
        .then((result) => {
          if (result.status === 200) {
            messageStore.sendMessage(t('message.deleteSuccess'))
          } else {
            messageStore.sendMessage(result.statusText, "error", t('message.deleteError'))
          }
          setSelectedDetail({})
          setSelectedProduct({})
          return productsListUpdate
      })
      .then(setProducts)
      .then(dispatch)
      .then(subLoadingCount)
      .then(dispatch)   
    };

    return (
      <Box>
        <Button variant="contained" data-testid='btnAdd'
          onClick={createProduct}>
            <AddItemIconContent/>{t('button.add')}
        </Button>
      
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} >
            <ProductHeader />
            <ProductsRows products={productsList} onEditClick={handleEditClick} handleDelete={handleDelete}/>
          </Table>
          <EditProductDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            isUpdate={isUpdate}
            product={selectedProduct}
            detail={selectedDetail}
          />
        </TableContainer>
    </Box>)
}


const ProductsRows = ({products, onEditClick, handleDelete}) => {
    return (<TableBody>
        {products?.map((row) => (
          <ProductView key={row.no} row={row} onEditClick={onEditClick} handleDelete={handleDelete}/>
        ))}
      </TableBody>)
}

const ProductHeader = () => {
const [t] = useTranslation();
return (
    <TableHead>
    <TableRow>
      <TableCell></TableCell>
      <TableCell>{t("product.item.innactive")}</TableCell>
      <TableCell align="left">{t("product.item.name")}</TableCell>
      <TableCell align="left">{t("product.item.category")}</TableCell>
      <TableCell align="right">{t("product.item.retailPrice")}</TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  </TableHead>)
    
}

export default ViewProducts;