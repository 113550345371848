import React, { useEffect } from 'react';
import { Button, Paper, Typography } from "@mui/material";
import userpool from "../../userpool";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { changePwsUrl } from '../../Navigator';
import { messageStore } from '../../common/InfoMessage';
import { logger } from '../../common/Logger';
import {emptyProfile, MainProfileSection} from './BaseProfile';

const ProfileSection = () => {
    const { t } = useTranslation();
    const user = userpool.getCurrentUser()
    const [profileData, setProfileData] = React.useState({})
    const navigate = useNavigate();

    useEffect(() => {
        if (user && Object.keys(profileData).length === 0) {
            // eslint-disable-next-line
            user.getSession((err, session) => {
                if (err) {
                    logger.error(err)
                    messageStore.sendMessage(t('message.unexpectedError'), "error")
                } else {
                    user.getUserAttributes((err, attributes) => {
                        if (err) {
                            logger.error(err)
                            messageStore.sendMessage(t('message.unexpectedError'), "error")
                        } else {
                            var data = {}
                            attributes.map((attribute) => {
                                data = {
                                    ...data,
                                    [attribute.getName()]: attribute.getValue()
                                }
                            })

                            setProfileData(data)
                        }
                    });
                }
            });
        }
    }, [user]);

    const convertProfile = (profileData, userName) => {
        const data = { ...emptyProfile,
            sub: profileData.sub || "",
            clientKey: profileData['custom:clientKey'] || "",
            subClientKey: profileData['custom:subClientKey'] || "",
            email: profileData.email || "",
            emailVerified: profileData.email_verified || "",
            phone: profileData.phone_number || "",
            phoneVerified: profileData.phone_number_verified || "",
            username: userName
        }
        return data
    }

    const data = convertProfile(profileData, user.getUsername())

    return (
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', width: '100%' }} component={'span'}>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>{t('signup.personalSection')}</Typography>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" component='div'>
                <MainProfileSection item={data} isEditMode={false} handleInputChange={() => {}}/>
                <Button variant="contained" color="primary" onClick={() => navigate(changePwsUrl)}>{t('session.changePws.title')}</Button>
            </Typography>
            
        </Paper>

    )
}


export default ProfileSection;