import { React } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



export default function ImageUpload({title, imageCallback}) {
    const handleUpload = ( event ) => {
        //event.target.files
        const fileReader = new FileReader();
    
        fileReader.readAsDataURL(event.target.files[0]);
        fileReader.onload = (e) => {
            imageCallback(e.target.result)
        };
    };
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      {title}
      <VisuallyHiddenInput
        type="file"
        onChange={handleUpload}
      />
    </Button>
  );
}
