import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, InputLabel, Checkbox, FormControl, Select, MenuItem, FormControlLabel } from '@mui/material';
import TextFieldStyle  from '../../input/TextFieldStyle';
import InfoItemTooltip from '../../card/InfoItemTooltip';
import { useTranslation } from 'react-i18next';
import { CATEGORY_MAP } from './BaseProduct';
import { useDispatch } from 'react-redux';
import { addLoadingCount, LoadingScreen, subLoadingCount } from '../../common/LoadingSlice';
import Products from '../../../api/Products';
import { askReloadProducts } from '../../common/CommonSlice';

const RETAILPRICE_FIELD_NAME = 'retailPrice';
const FACTOR_FIELD_NAME = 'factor';
const UNIT_FIELD_NAME = 'unitPrice';

const EditProductDialog = ({ open, onClose, product, detail, onSave, isUpdate }) => {
  const [editedProduct, setEditedProduct] = useState({ ...product });
  const [editedDetail, setEditedDetail] = useState({ ...detail });
  const [t] = useTranslation();
  const dispatch = useDispatch()

  React.useEffect(() => {
    setEditedProduct({ ...product });
    setEditedDetail(detail);
  }, [detail, product]);
  
  
  const handleProductChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      if (editedProduct.billingNameFr === editedProduct.name) {
        setEditedProduct((prev) => ({ ...prev, billingNameFr: value }));
      }
      if (editedProduct.billingNameEn === editedProduct.name) {
        setEditedProduct((prev) => ({ ...prev, billingNameEn: value }));
      }
    } else if (name === RETAILPRICE_FIELD_NAME) {
      setEditedDetail((prev) => ({ ...prev, [FACTOR_FIELD_NAME]: getFactor(prev.unitPrice, value)}));
    }
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    if (name === FACTOR_FIELD_NAME) {
      setEditedProduct((prev) => ({ ...prev, [RETAILPRICE_FIELD_NAME]: getRetailPrice(editedDetail.unitPrice, value)}));
    } else if (name === UNIT_FIELD_NAME) {
      setEditedProduct((prev) => ({ ...prev, [RETAILPRICE_FIELD_NAME]: getRetailPrice(value, editedDetail.factor)}));
    }
    setEditedDetail((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    let curItem = JSON.parse(JSON.stringify(editedDetail))
    delete curItem.factor

      console.log('Saved product:', editedProduct);
      console.log('Saved detail:', editedDetail);
      dispatch(addLoadingCount())
      const pApi = new Products()
      return pApi.Get().then((productsList) => {
        let productsListUpdate = JSON.parse(JSON.stringify(productsList))
        if (isUpdate) {
          const index = productsListUpdate.findIndex(obj => obj.no === editedProduct.no)
          delete productsListUpdate[index]
          productsListUpdate = productsListUpdate.filter(n => n)

          pApi.UpdateDetail(editedProduct.no, editedDetail)
        } else {        
          pApi.InsertDetail(editedProduct.no, editedDetail)
        }
        productsListUpdate.push(editedProduct)
        return pApi.Update(productsListUpdate)
        .then(askReloadProducts)
        .then(dispatch)
        .then(subLoadingCount)
        .then(dispatch)
        .then(() => {
          if (onSave) {
            return onSave(productsListUpdate, editedProduct, editedDetail)
          }
        })
        .then(() => onClose())
      })
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: !checked }));
  };

  
  const getFactor = (unitPrice, retailPrice) => {
    return (retailPrice / unitPrice).toFixed(2)
  }

  const getRetailPrice = (unitPrice, factor) => {
    return (unitPrice * factor).toFixed(2)
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingScreen />
      <DialogTitle>{t(isUpdate ? "product.editTitle": "product.createTitle")}</DialogTitle>
      <DialogContent>
        <InfoItemTooltip
            noName={t('product.item.no')} 
            no={product.no} 
            updateDate={detail.updateDate} 
            creationDate={detail.creationDate} />
       
       <FormControlLabel
          control={
            <Checkbox
              checked={!editedProduct.isActif} 
              onChange={handleCheckboxChange}
              name="isActif"
              color="primary"
            />
          }
          label={t("product.item.innactive")}
        />

        <TextFieldStyle
          label={t("product.item.nameLong")}
          name="name"
          value={editedProduct.name}
          onChange={handleProductChange}
          fullWidth
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Categorie</InputLabel>
          <Select
            label={t("product.item.category")}
            name="category"
            value={editedProduct.category}
            onChange={handleProductChange}
          >
            {Object.entries(CATEGORY_MAP).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextFieldStyle
          label={t("product.item.billFr")}
          name="billingNameFr"
          value={editedProduct.billingNameFr}
          onChange={handleProductChange}
          fullWidth
        />
        <TextFieldStyle
          label={t("product.item.billEn")}
          name="billingNameEn"
          value={editedProduct.billingNameEn}
          onChange={handleProductChange}
          fullWidth
        />
        <TextFieldStyle
          label={t("product.item.upc")}
          name="upc"
          value={editedDetail.upc}
          onChange={handleDetailChange}
          fullWidth
        />
        <TextFieldStyle
          label={t("product.item.din")}
          name="din"
          value={editedDetail.din}
          onChange={handleDetailChange}
          fullWidth
        />
        <InputLabel  htmlFor="incRxFee" sx={{ mx: 2 }}>
          {t("product.item.rxFee")}
          <Checkbox label={t('product.item.rxFee')}  sx={{ mx: 2 }}
            id="incRxFee" inputProps={{ 'data-testid': 'chkRxFee' }}
            name="incRxFee"
            checked={product.incRxFee} 
            onChange={handleProductChange}
            />
        </InputLabel> 
        <TextFieldStyle
          label={t("product.item.cost")}
          name={UNIT_FIELD_NAME}
          type="number"
          value={editedDetail.unitPrice}
          onChange={handleDetailChange}
          fullWidth
        />
        <TextFieldStyle
          label={t("product.item.factor")}
          name={FACTOR_FIELD_NAME}
          type="number"
          value={editedDetail.factor}
          onChange={handleDetailChange}
          fullWidth
        />
        <TextFieldStyle
          label={t("product.item.retailPrice")}
          name={RETAILPRICE_FIELD_NAME}
          type="number"
          value={editedProduct.retailPrice}
          onChange={handleProductChange}
          fullWidth
        />
        <TextFieldStyle
          label={t("product.item.more")}
          name="more"
          multiline
          value={editedDetail.more}
          onChange={handleDetailChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("button.cancel")}</Button>
        <Button onClick={handleSave} color="primary">{t("button.save")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductDialog;