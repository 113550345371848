import env from '../env/env.json';
import axios from 'axios';
import { logger } from '../component/common/Logger';


const ELEMENT_NAME = "action/signup"
class Signup {
    startRegistration(data) {
        const APIEndPoint = env.signupApi + ELEMENT_NAME
        const startAction = async (data) => {
            try {
              const response = await axios.post(APIEndPoint, data,
                {
                 headers: {
                  "X-API-Key": env.signupKey,
                  "Content-Type": "application/json"
                }
                });
              //response.data = data
              logger.debug('Response:', response.data);
              return response
            } catch (error) {
              logger.error('Error DecreateLimit :', error);
              //throw new Error(error.message)
            }
        };
        return startAction(data) 
    }
 }

export default Signup

