import React from 'react'
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DayPicker from '../../date/DatePicker';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import { DeleteIcon } from '../../common/IconFactory';
import { useTranslation } from 'react-i18next';
import { StandardTextField } from '../../input/TextFieldStyle';


function PrescriptionTable({items, handleInputChange, deleteItem, isEditMode, itemType, isPrintMode}) {
  const { t } = useTranslation(); 
  const rows = isEditMode ? getEditRow(items, handleInputChange, deleteItem, itemType, t) : getViewRow(items, itemType, isPrintMode)
  const headers = isEditMode ? getEditHeader(itemType, t) : getViewHeader(itemType, isPrintMode, t)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }}>
        {headers}
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function getEditHeader(itemType, t) {
  return (<TableHead>
    <TableRow>
      {itemType !== "3" && <TableCell ><b>{t('rx.item.name')}</b></TableCell>}
      {itemType !== "3" && <TableCell ><b>{t('rx.item.posologie')}</b></TableCell>}
      {itemType !== "3" && <TableCell sx={{ display: { sm: 'block', xs: 'none' } }}><b >{t('rx.item.renouvellement')}</b></TableCell>}
      {itemType !== "3" && <TableCell sx={{ display: { sm: 'none', xs: 'block' } }}><b >{t('rx.item.renouvellementShort')}</b></TableCell>}
      {itemType === "2" && <TableCell  align="center"><b>Expiration</b></TableCell>}
      <TableCell width="44px" align="right"></TableCell>
    </TableRow>
  </TableHead>)
}

function getViewHeader(itemType, isPrintMode, t) {
  return (<TableHead>
    <TableRow>
      {itemType !== "3" && <TableCell ><b>{t('rx.item.name')}</b></TableCell>}
      {itemType !== "3" && <TableCell ><b>{t('rx.item.posologie')}</b></TableCell>}
      {itemType !== "3" && <TableCell sx={{ display: { sm: 'block', xs: 'none' } }}><b >{t('rx.item.renouvellement')}</b></TableCell>}
      {itemType !== "3" && <TableCell sx={{ display: { sm: 'none', xs: 'block' } }}><b >{t('rx.item.renouvellementShort')}</b></TableCell>}
      {itemType === "2" && <TableCell align="center"><b>Expiration</b></TableCell>}
    </TableRow>
  </TableHead>)
}

function getEditRow(items, handleInputChange, deleteItem, itemType, t) {
    return (
      items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {(itemType === "3") && 
            <TableCell align="left" component="th" scope="row" width={(itemType !== "3") ? "40%": "90%"}>
              <StandardTextField label={t('rx.item.detail')} 
                required
                testid="Rx details"
                fullWidth
                multiline
                maxRows={8}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                value={row.name} variant="standard"/>
            </TableCell>
          }   
          {(itemType !== "3") && 
            <>
            <TableCell align="left"  width="40%">
              <StandardTextField label={t('rx.item.nameLong')}
                required
                fullWidth
                maxLength={60}
                testid="Med name"
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                value={row.name} variant="standard"/>
            </TableCell>
            <TableCell align="left" >
              <StandardTextField label={t('rx.item.posologieLong')}
                required
                fullWidth
                multiline
                testid="Med dosage"
                maxRows={8}
                onChange={(e) => handleInputChange(index, 'posologie', e.target.value)}
                value={row.posologie} variant="standard"/>
            </TableCell>
            <TableCell align="left" >
              <StandardTextField label={t('rx.item.renouvellement')}
                type="number"
                required
                fullWidth
                testid="ren"
                slotProps={{
                  htmlInput: {
                      min:0,
                      max:99,
                      step:"1"
                  }
              }}
                maxRows={2}
                onChange={(e) => handleInputChange(index, 'ren', e.target.value)}
                value={row.ren} variant="standard"/>
            </TableCell>
            </>
          }
          {(itemType === "2") && 
              <td>
                <DayPicker 
                  inputChange={(e) => handleInputChange(index, 'expiration', convertToSystemDateFormat(e))} 
                  testid="Expiration"
                  minDate={new Date()}
                  required={itemType === "2"} 
                  value={row.expiration}/>
              </td>
          }
          <TableCell align="center" padding='none'><DeleteIcon key={'btnDelete'+index} onClick={() => deleteItem(index)}/></TableCell>
        </TableRow>
      )))
}

function getViewRow(items, itemType) {
    return (
      items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell className='display-linebreak' component="th" scope="row" size='small' width={itemType !== "3" ? "40%": "90%"}>
            { row.name}
          </TableCell>
          {itemType !== "3" &&
            <>
              <TableCell className='display-linebreak' size='small'>
                { row.posologie}
              </TableCell>
              <TableCell className='display-linebreak' size='small'>
                { row.ren || 0 }
              </TableCell>
            </>
          } 
          {itemType === "2" &&
            <TableCell align="center" size='small'>
              { row.expiration}
            </TableCell>
          }
        </TableRow>
      )))
}

export default PrescriptionTable