import { GetCommon } from "../../api/Common";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { logger } from "../common/Logger";



export const addRow = (currentItems, setItems, emptyItem) => {
    var newItems = JSON.parse(JSON.stringify(currentItems))
    newItems.items = [...newItems.items, JSON.parse(JSON.stringify(emptyItem))]
    setItems(newItems)
    return newItems
  };


export const deleteRow = (currentItems, setItems, index) => {
    var newItems = JSON.parse(JSON.stringify(currentItems))
    delete newItems.items[index]
    newItems.items = newItems.items.filter( Array )
    setItems(newItems)
    return newItems
  };


  export const setNextNoHandler = async (itemNoFieldName, currentItem, setItem, dispatch) => {
    dispatch(addLoadingCount())
    new GetCommon().then((common) => {
      const nextNo = common[itemNoFieldName]
      logger.debug("Next no will be : " + nextNo)
      var newItem = {...currentItem}
      newItem.no = nextNo
      setItem(newItem)
    })
    .finally(()=>dispatch(subLoadingCount()))
  }

  // Fonction pour mettre à jour les valeurs des champs
  //If index is null, it means that the field is not in the items array
  export const handleInputChange = (originalObject, setMethod, index, fieldName, value) => {
    if (index != null && originalObject.items[index][fieldName] !== value) {
      logger.debug("current object : ", originalObject)
      var newObject = JSON.parse(JSON.stringify(originalObject))
      
      newObject.items[index][fieldName] = value;
      setMethod(newObject)
      logger.debug("Change ", fieldName, "to", value)
      return newObject
    } else if (index === null && fieldName) {
      const newObject = {...originalObject,
        [fieldName]: value
      }
      setMethod(newObject)
      logger.debug("Change ", fieldName, "to", value)
      return newObject
    }
  };
  