import React, { useEffect, useState } from 'react'
import EmptyView from '../../view/EmptyView';
import { Paper, Typography } from '@mui/material';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import { useTranslation } from 'react-i18next';
import { ConfigGeneralPreference } from './section/ConfigPreference';
import { useDispatch, useSelector } from 'react-redux';
import {updateHeader} from './BaseHeader'
import { LoadHeader } from '../../headers/HeaderLoader';

export function CiePreferenceView() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const sliceHeader = useSelector(state => state.common.header)?.payload
  const [curHeader, setCurHeader] = useState();

  useEffect(() => {
    if (curHeader === undefined || curHeader === null) {
      setCurHeader(sliceHeader)
    }
  
  }, [sliceHeader]);

  if (curHeader === undefined || curHeader === null) {
    return (
      <EmptyView title={t('header.noSelection')}>
        <LoadHeader />
      </EmptyView>
    )
  }
  const handleInputChange = (fieldName, value, section) => {
    if (section) {
      setCurHeader( {...curHeader,
        preference: 
          {...curHeader.preference,
            [section]:  {
              ...curHeader.preference[section],
              [fieldName]: value
            }
          }
      })
    } else {
      setCurHeader( {...curHeader,
        preference: 
          {...curHeader.preference,
            [fieldName]: value
          }
      })
    }
  };

  const getView =(curHeader) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', width: '100%' }} component={'span'}>
        <LoadHeader />
        <div border="1">
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
          {t('header.config.title')}
          </Typography>
          <form id="prefForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
            <ConfigGeneralPreference 
              item={curHeader.preference} 
              handleConfigInputChange={handleInputChange} 
              isEditMode={true} /> 
            <CreateButtonSection />
          </form>
        </div>
      </Paper>
      )
  }

    return getView(curHeader)
}