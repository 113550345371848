import React, { useState } from 'react'
import "./vue.css";     
import OrganizationChart from "organization-chart-react";
import { useSelector } from 'react-redux';
import TabSelector from '../layout/TabSelector';
import Animals from '../../api/Animals';
import { useDispatch } from "react-redux"
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { messageStore } from '../common/InfoMessage';
import { logger } from '../common/Logger';
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../common/ClientSlice';
import { useNavigate } from 'react-router-dom';
import ClientCard from './ClientCard';
import { constructAnimalsCard } from './AnimalCard'
import { constructBillSection } from './BillCard'

function View360() {
  const dispatch = useDispatch()
  const pdfContentRef = React.createRef();
  const navigateAction = useNavigate()
  const curClient = useSelector(state => state.client.client)
  
  //Setup client header
  dispatch(setShowClearAnimalsSelector(false)) 
  dispatch(setShowClientsSelector(true))
  dispatch(setShowAnimalsSelector(false))
  dispatch(setAllowSelection(true))


  const [clientNoLoad, setClientNoLoad] = useState(null);
  const [dataMap, setDataMap] = useState({});

  const loadAnimals = async (optAnimals) => {
    logger.debug("Loading animals (loadAnimals) : ", optAnimals)
    var animalData = []
    for (const animalKey of optAnimals) {
      dispatch(addLoadingCount())
      await new Animals().Get(animalKey)
        .then((animal) => {
        // logger.debug("Animal loaded : ", animal)
        animalData.push(animal)
        })
        .finally(()=>dispatch(subLoadingCount()))
    }

    return animalData
  }

  if (curClient?.payload && curClient?.payload.no !== clientNoLoad) {
    logger.debug("New client to load. #", curClient.payload.no)
    //setDataMap(constructClientRelation(curClient.payload))
    loadAnimalsList(curClient.payload.no, dispatch)
    .then(loadAnimals)
    .then((animalsData) => {
      logger.debug("Animals loaded")
      logger.debug("animals : ", animalsData)
      const clientCard = ClientCard(navigateAction, curClient.payload)
      const animalsCard = constructAnimalsCard(navigateAction, dispatch, animalsData)
      const billSection = constructBillSection(navigateAction, curClient.payload)
      const clientChildren = [billSection, ...animalsCard].filter(n => n)
      clientCard.children = clientChildren.length === 0 ? null : clientChildren

      setDataMap(clientCard)
    })
    setClientNoLoad(curClient.payload.no)
  }

  return ( 
    <>
      <TabSelector 
        pageTitle="Vue 360"
        availableView={[{key: 0, title: "Dossier client", name: "view"}]}
        currentView={"view"}
        />
        
        <div ref={pdfContentRef} id="toByPrint" className="card overflow-x-auto">
               
          <OrganizationChart data={dataMap} onClickNode={() => {}}  />
        </div>
        
    </>)
}

const loadAnimalsList = (clientNo, dispatch) => {
  dispatch(addLoadingCount())
  return new Animals().List()
      .then((allAnimals) => {
        const optAnimals = allAnimals.filter(a => a.substring(0, a.indexOf("/")) === clientNo.toString())
        //logger.debug("optionsAnimals : ", optAnimals)
        return optAnimals    
      })
      .catch((error) => {messageStore.sendMessage(error.message, "error")})
      .finally(()=> dispatch(subLoadingCount()))
}

export default View360