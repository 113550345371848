import React from 'react';
import { FormControl, Input, InputLabel, styled } from "@mui/material";
import { IMaskInput } from 'react-imask';

//const TextFieldStyle = styled(TextField)({ marginTop:16, marginBottom:16 });

const TextMaskCustom = React.forwardRef(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+1(000) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);



export const PhoneTextField = styled((props) => (
  <FormControl variant="standard">
    <InputLabel htmlFor="formatted-text-mask-input">{props.label}</InputLabel>
    <Input 
      {...props}
      inputComponent={TextMaskCustom}
    />
  </FormControl>
))({
  marginTop: 16,
  marginBottom: 16,
});


export default PhoneTextField