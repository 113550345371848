import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FlatHeaderRenderer from '../header/FlatHeaderRenderer';
import { formatToDisplayFormatFromString } from '../date/DateUtils'
import {formatToSystemDate} from '../date/DateUtils'
import FactureTable from './FactureTable';
import { useDispatch, useSelector } from 'react-redux';
import {ViewButtonSection} from '../view/ViewButtonSection';
import { handleDownload } from '../GeneratePDF';
import EmptyView from '../view/EmptyView';
import BillSelector from './BillSelector';
import { extraSection } from './BaseFacture';
import { useTranslation } from 'react-i18next';
import { LoadHeader } from '../headers/HeaderLoader';
import { logger } from '../common/Logger';
import { hasDeletePermission } from '../../services/AccessHub';
import { updateClient } from '../clients/ClientUtils';

const handlePaid = (bill, curClient, setNewBill, dispatch, t) => {
  logger.debug("Pay the bill")
  const updateBill = {...bill,
    isPaid: true,
    paidDate: formatToSystemDate(new Date()),
    updateDate: formatToSystemDate(new Date())
  }

  var clientUpdate = JSON.parse(JSON.stringify(curClient))
  logger.debug("CurBill : ",  clientUpdate.bills)
  const objIndex = clientUpdate.bills.findIndex(obj => obj.no === updateBill.no);

  clientUpdate.bills[objIndex] = updateBill
  logger.debug("New bill : ",  clientUpdate.bills)

  return updateClient(clientUpdate, false, t('billing.paySaveSuccess'), t('saveError'), dispatch)
  .then(() => {setNewBill(updateBill)})
}


export function ViewBill({itemNo, handleEdit, saveCallback}) {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const [bill, setBill] = useState(itemNo && itemNo >= 0 ? curClient?.payload?.bills?.find(obj => obj.no === itemNo) : null);

  if (bill && (curClient?.payload?.bills === undefined ||  curClient.payload.bills.findIndex(obj => obj.no === bill.no) === -1)) {
    logger.debug("Change selected bill")
    setBill(null)
    return (<GetEmptyView client={curClient} setItem={setBill}/>)
  }
  
  const getPdfName = () => {
  const baseName = bill.no + "-" + curClient.payload.name 
      return bill.animal?.name ? baseName + " - " + bill.animal?.name + ".pdf" : baseName + ".pdf"
  }
  


  const deleteBill = (itemToDelete, dispatch, t) => {
  
    var clientUpdate = JSON.parse(JSON.stringify(curClient?.payload))
  
    const objIndex = curClient?.payload.bills.findIndex(obj => obj.no === itemToDelete.no);
    delete clientUpdate.bills[objIndex]
    clientUpdate.bills = clientUpdate.bills.filter(n => n)
  
    return updateClient(clientUpdate, false, t('message.deleteSuccess'), t('message.deleteError'), dispatch)
      .then(() => {saveCallback(clientUpdate.key)})
  }


  const getView =(header, client, bill, isPrintMode, dispatch) => {
    return (
        <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
          <LoadHeader />
          <Typography color="text.secondary" align='center'>
            <BillSelector client={client} bill={bill} setBill={setBill}/>
          </Typography>
          <div ref={pdfContentRef} id="toByPrint" border="1" style={{width: isPrintMode ? '780px' : ''}}>
    
            <FlatHeaderRenderer 
              displayHeaderLeft={true}
              isPrintMode={isPrintMode}
              headerData={header} 
              clientData={client} 
              animalData={bill ? bill.animal : null}
              extraData={{"title":  t('billing.item.title') + " :",
                "noTitle": t('billing.item.no'),
                "no": bill.no,
                "date": formatToDisplayFormatFromString(bill.date)}}
              extraChildren={extraSection(bill, header?.preference)}  
            />
            
      
            <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
              {t('billing.mainTitle')}
            </Typography>
                  
            <FactureTable 
              items={bill.items} 
              isEditMode={false}
              isPrintMode={isPrintMode}/>
            
            <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" variant="h6" data-testid={"finalTotal"}>
              {t('billing.totalLong')} : {bill.total} $
            </Typography>
          </div>
        { (bill.isPaid === undefined || bill.isPaid === false ) && 
            <Button variant="contained"
                onClick={() => {
                  handlePaid(bill, curClient.payload, setBill, dispatch, t)
                  .then(() => {saveCallback(curClient.payload.key, bill)})
                }} >{t('billing.payBill')}</Button>
        }
          <ViewButtonSection 
            allowEdit={!bill.isPaid} 
            selectedItem={bill}
            handleEdit={() => handleEdit(bill)}
            allowDelete={hasDeletePermission() && !bill.isPaid}
            handleDelete={deleteBill}
            handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
            />
        </Paper>
    );
  }

  if (bill === undefined || bill === null) {
    return (<GetEmptyView client={curClient} setItem={setBill}/>)
  }

  return getView(header?.payload, curClient.payload, bill, isPrintMode, dispatch)
    
}

const GetEmptyView = ({client, setItem}) => {
  const { t } = useTranslation();
  return (
    <EmptyView title={t('billing.noSelection')}>
      <BillSelector client={client?.payload} setBill={setItem}/>
    </EmptyView>
  )
}

