import { IconButton } from '@mui/material'
import React from 'react'

//itemNoToView
const ComponentCard = (onItemClick, navUrl, title, items, icon) => {
    var childrens = []
    if (items && items.length > 0) {
      for (const childs of items) {
        childrens.push({
          name: <IconButton size='small' onClick={() => onItemClick(navUrl, childs.no)}>{childs.date}</IconButton>,
        })
      }
    
  
      return {
        title: icon ? <>{icon} {title}</> : title,
        member: childrens
      }
    }
    return null
  }
  

  export default ComponentCard