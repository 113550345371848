import { useEffect, React, useState } from "react";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSignature } from "../common/CommonSlice";
import Headers from "../../api/Headers";
import { Box, Button, Typography } from "@mui/material";
import ImageUpload from "../input/ImageUpload";
import { SaveIconContent } from "./IconFactory";

const signFileName = "signature.encode"

export const SignatureSection = () => {
    const dispatch = useDispatch()
    const signature = useSelector(state => state.common.signature)?.payload;
  
    useEffect(()=>{
      if (signature === undefined || signature === null) {
        dispatch(addLoadingCount())
        new Headers().GetData(signFileName)
          .then(setSignature)
          .then(dispatch)
          .finally(()=>dispatch(subLoadingCount()))
      }
  },[]);

  return  (<img className='Signature' src={`data:image/jpeg;base64,${signature}`} />)

}



export const SignatureEditSection = () => {
  const dispatch = useDispatch()
  const [fileData, setFileData] = useState(null)

  const save = () => {
    const b64Data = fileData.substring(fileData.lastIndexOf(';base64,') + 8)

    dispatch(addLoadingCount())
    new Headers().SaveImage(signFileName, b64Data)
      .then(()=>dispatch(setSignature(b64Data)))
      .then(setFileData)
      .finally(()=>dispatch(subLoadingCount()))
  }


  return (
    <Box direction="column">
      <Typography >
        Signature actuelle
      </Typography>
      <SignatureSection />

      {fileData && <>
          <Typography >
            Nouvelle signature
          </Typography>
          <Box>
            <img src={`${fileData}`}  className='Signature'/>
          </Box>
        </>}
      <Box>
        <ImageUpload imageCallback={setFileData} title='Changer la signature'/>
      </Box>
      {fileData && 
      <Button variant="contained" data-testid='btnSaveSignature' type="submit" onClick={save} startIcon={<SaveIconContent/>}>
        Confirmer le changement</Button>}
        
    </Box>
  )

}

