import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import {updateHeader} from '../cie/BaseHeader'
import { LoadHeader } from '../../headers/HeaderLoader';
import { GetTypeSelector } from '../../medic/rxs/BasePrescription';

export function LayoutRx() {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const sliceHeader = useSelector(state => state.common.header)?.payload
  const [curHeader, setCurHeader] = useState();

  useEffect(() => {
    if (curHeader === undefined || curHeader === null) {
      setCurHeader(sliceHeader)
    }
  }, [sliceHeader]);


  const handleInputChange = (fieldName, value) => {
      let newHeader = JSON.parse(JSON.stringify(curHeader));
      newHeader.layout.rx[fieldName] = value;
      setCurHeader(newHeader);
  };

  const getView =(curHeader) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
        <LoadHeader />
        <div border="1" >
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            {t('layout.rx.title')}
            </Typography>
            <form id="cieForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
              <Box sx={{ my: 2, mx: 4 }} >
              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                {t('layout.defaultValues')}
              </Typography>
              


              <GetTypeSelector
                curValue={curHeader.layout.rx.type}
                onChangeHandler={(index, fieldName, value) => handleInputChange(fieldName, value)}
                isEditMode={true}
                />
                <CreateButtonSection />
              </Box>
            </form>
          </div>
        </Paper>
      )
  }
  if (curHeader?.layout === undefined || curHeader?.layout === null) {
    return (<></>)
  }
  return getView(curHeader)
}

