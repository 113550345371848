export const GetAllHeader = (t, canCreate) => {
  if (canCreate === false)
    return canCreate === false ? [getEdit(t), getView(t)] : [getCreate(t), getEdit(t), getView(t)]
}

 export const GetNoSelectionHeader =  (t, canCreate) => {
    return canCreate === false ? [getView(t)] : [getCreate(t), getView(t)]
}

export const getCreate = (t) => {
  return {key: 0, title: t('view.create'), name: "create" }
}

export const getView = (t) => {
  return {key: 1, title: t('view.view'), name: "view"}
}


export const getEdit = (t) => {
  return {key: 2, title: t('view.edit'), name: "edit"}
}
  