import React from 'react'
import { Avatar, Box, Tooltip, Typography } from "@mui/material"


const KeyVal = ({label, value, icon, iconTooltip}) => {
    return (<Box sx={{display: 'inline-flex'}}>
         {icon && 
            <Tooltip title={iconTooltip} >
            <Avatar src={icon} sx={{ width: 56, height: 56 , mx: 2}}/>
            </Tooltip>
        }
        {label && 
          <Typography sx={{ alignContent: 'center', mx: 2  }}>
          {label} :
        </Typography>
        }
        <Typography sx={{ alignContent: 'center', mx: 2  }}>
        {value}
        </Typography>
    </Box> ) 

}


export default KeyVal