import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Grid, Toolbar, Typography } from '@mui/material';

function TabSelector({pageTitle, availableView, currentView, onViewChange}) {
  const [viewIndex, setViewIndex] = React.useState(0);



  const viewChangeHandler = (index, newView) => {
    onViewChange(newView)
    setViewIndex(index)
  }
  
  availableView?.map((row, index) => {
    if (currentView === row.name && viewIndex !== index) {
      setViewIndex(index)
    }}
  )

    return (
    <React.Fragment>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {pageTitle}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Tabs  key={viewIndex} value={viewIndex} textColor="inherit" 
          variant="scrollable"
          allowScrollButtonsMobile={true}
          scrollButtons={true}>
          {availableView && availableView.map((row, index) => (
            <Tab aria-label={row.name} key={index} label={row.title} onClick={() => viewChangeHandler(index, row.name)} />
          ))}        
        </Tabs>
        
      </AppBar>
    </React.Fragment>)
}


export default TabSelector