import React from 'react'
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

import Divider from '@mui/material/Divider';
import { formatToSystemDate } from '../../date/DateUtils';
import WeightInput from '../../input/WeightInput';
import { GetWeightType } from '../../common/CommonSlice';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LoadHeader } from '../../headers/HeaderLoader';
import TextFieldStyle from '../../input/TextFieldStyle';

const GetEmptyConsultation = (layout) => {
  
  const defTemplate = layout.consultation.template[layout.consultation.default]

  return {
    "isActif": true, 
    "date": formatToSystemDate(new Date()), 
    "consultation": {
        "consultWhy": defTemplate.consultWhy, 
        "appetit": defTemplate.appetit, 
        "vomissement": defTemplate.vomissement, 
        "urines": defTemplate.urines, 
        "selles": defTemplate.selles, 
        "reactionVaccin": defTemplate.reactionVaccin, 
        "knowProblem": defTemplate.knowProblem, 
        "medicaments": defTemplate.medicaments, 
        "anamnese": defTemplate.anamnese, 
        "etatChair": defTemplate.etatChair, 
        "weight": defTemplate.weight, 
        "weightType": defTemplate.weightType, 
        "temperature": defTemplate.temperature, 
        "fc": defTemplate.fc, 
        "fr": defTemplate.fr, 
        "mugueuses": defTemplate.mugueuses, 
        "trc": defTemplate.trc, 
        "hydratation": defTemplate.hydratation, 
        "etatGenerale": defTemplate.etatGenerale, 
        "yeux": defTemplate.yeux, 
        "oreilles": defTemplate.oreilles, 
        "nez": defTemplate.nez, 
        "gueuleDents": defTemplate.gueuleDents, 
        "cardio": defTemplate.cardio, 
        "respiratoire": defTemplate.respiratoire, 
        "abdomen": defTemplate.abdomen, 
        "teguments": defTemplate.teguments, 
        "myoarthrosquelettique": defTemplate.myoarthrosquelettique, 
        "noeudsLymphatiques": defTemplate.noeudsLymphatiques,
        "neurologique": defTemplate.neurologique,
        "reproducteur": defTemplate.reproducteur,
        "toucherRectal": defTemplate.toucherRectal,
        "thyroide": defTemplate.thyroide,
        "analyse": defTemplate.analyse,
        "plan": defTemplate.plan,
    "no": "",
    "link": {}}
  }
}

function GetConsultationSection({consultationData, animalData, isEditMode, handleInputChange}) {
  const { t } = useTranslation();
  const curHeader = useSelector(state => state.common.header)?.payload?.preference

    const variant = 'standard'
    return <>
      <LoadHeader />
      <Box>
        <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
          <TextFieldStyle label={t('consultation.item.motif')}
              variant={variant}
              fullWidth
              disabled={!isEditMode} 
              onChange={(e) => handleInputChange('consultWhy', e.target.value)}
              value={consultationData.consultWhy} />

          <TextFieldStyle label={t('consultation.item.appetite')} 
              variant={variant}
              fullWidth
              disabled={!isEditMode} 
              onChange={(e) => handleInputChange('appetit', e.target.value)}
              value={consultationData.appetit} />
          
          <TextFieldStyle label={t('consultation.item.vomit')} 
              variant={variant}
              fullWidth
              disabled={!isEditMode} 
              onChange={(e) => handleInputChange('vomissement', e.target.value)}
              value={consultationData.vomissement} />
          
          <TextFieldStyle label={t('consultation.item.urines')}
              variant={variant}
              fullWidth
              disabled={!isEditMode} 
              onChange={(e) => handleInputChange('urines', e.target.value)}
              value={consultationData.urines} />
          <TextFieldStyle label={t('consultation.item.stools')}
              variant={variant}
              fullWidth
              disabled={!isEditMode} 
              onChange={(e) => handleInputChange('selles', e.target.value)}
              value={consultationData.selles} />
            

          <TextFieldStyle label={t('consultation.item.oldReaction')}
            variant={variant}
            fullWidth
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('reactionVaccin', e.target.value)}
            value={consultationData.reactionVaccin} 
            multiline/>

          <TextFieldStyle label={t('consultation.item.knowProblem')}
            variant={variant}
            fullWidth
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('knowProblem', e.target.value)}
            value={consultationData.knowProblem} 
            multiline/>
            
          <TextFieldStyle label={t('consultation.item.drug')}
            variant={variant}
            fullWidth
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('medicaments', e.target.value)}
            value={consultationData.medicaments}
            multiline />
          

          <TextFieldStyle label={t('consultation.item.anamnesis')}
            variant={variant}
            fullWidth
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('anamnese', e.target.value)}
            value={consultationData.anamnese} 
            multiline/>
        </Typography>
        <Divider/>
        <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
          <Box width="50%">
          {t('consultation.item.flesh')}
            <Slider
            aria-label={t('consultation.item.flesh')}
            value={consultationData.etatChair}
            getAriaValueText={() => {return consultationData.etatChair}}
            onChange={(e) => handleInputChange('etatChair', e.target.value)}
            disabled={!isEditMode}
            step={1}
            marks
            min={0}
            max={9}
            valueLabelDisplay="on"
          />
          </Box>
          
          <WeightInput 
            weight={consultationData.weight}
            weightType={consultationData.weightType}
            isEditMode={isEditMode} 
            defaultWeightType={GetWeightType(curHeader, "consultation")}
            onWeightChange={(newValue) => handleInputChange('weight', newValue)}
            onWeightTypeChange={(newValue) => handleInputChange('weightType', newValue)}
            />

          <TextFieldStyle label="Température" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode}
            onChange={(e) => handleInputChange('temperature', e.target.value)}
            value={consultationData.temperature || "NE"} 
            type={isEditMode || consultationData.temperature ? "number" : "text"}
            maxLength={4}
            slotProps={{
              htmlInput: {
                min:0,
                max:999,
                step:"0.1"
              }
            }}/>
          
          <TextFieldStyle label="FC" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode}
            onChange={(e) => handleInputChange('fc', e.target.value)}
            value={consultationData.fc} 
            type="number"
            maxLength={3}
            slotProps={{
              htmlInput: {
                min:0,
                max:999
              }
            }}/>
          
          <TextFieldStyle label="FR" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('fr', e.target.value)}
            value={consultationData.fr}/>


          <TextFieldStyle label="Mugueuses" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('mugueuses', e.target.value)}
            value={consultationData.mugueuses} />

          <TextFieldStyle label="TRC" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('trc', e.target.value)}
            value={consultationData.trc} />
            
          <TextFieldStyle label="Hydratation" 
            variant={variant}
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('hydratation', e.target.value)}
            value={consultationData.hydratation} />

          <TextFieldStyle label="État général"
            variant={variant}
            disabled={!isEditMode}
            onChange={(e) => handleInputChange('etatGenerale', e.target.value)}
            value={consultationData.etatGenerale} />

          <TextFieldStyle label="Yeux" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('yeux', e.target.value)}
            value={consultationData.yeux} 
            multiline/>

          <TextFieldStyle label="Oreilles" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('oreilles', e.target.value)}
            value={consultationData.oreilles} 
            multiline/>

          <TextFieldStyle label="Nez" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('nez', e.target.value)}
            value={consultationData.nez} 
            multiline/>

          <TextFieldStyle label="Gueule/Dents"
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('gueuleDents', e.target.value)}
            value={consultationData.gueuleDents} 
            multiline/>

          <TextFieldStyle label="Cardiovasculaire"
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('cardio', e.target.value)}
            value={consultationData.cardio} 
            multiline/>

          <TextFieldStyle label="Respiratoire"
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('respiratoire', e.target.value)}
            value={consultationData.respiratoire} 
            multiline/>
          
          <TextFieldStyle label="Abdomen" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('abdomen', e.target.value)}
            value={consultationData.abdomen} 
            multiline/>

          <TextFieldStyle label="Téguments" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('teguments', e.target.value)}
            value={consultationData.teguments} 
            multiline/>

          <TextFieldStyle label="Myoarthrosquelettique" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('myoarthrosquelettique', e.target.value)}
            value={consultationData.myoarthrosquelettique} 
            multiline/>

          <TextFieldStyle label="Noeuds lymphatiques" 
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('noeudsLymphatiques', e.target.value)}
            value={consultationData.noeudsLymphatiques}
            multiline/>

          <TextFieldStyle label="Neurologique"
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('neurologique', e.target.value)}
            value={consultationData.neurologique} 
            multiline/>

          <TextFieldStyle label="Reproducteur"
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('reproducteur', e.target.value)}
            value={consultationData.reproducteur} 
            multiline/>

          <TextFieldStyle label="Toucher rectal"     
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('toucherRectal', e.target.value)}
            value={consultationData.toucherRectal} 
            multiline/>

        {(animalData && animalData.type === "cat") && 
          <TextFieldStyle label="Thyroïde"
            variant={variant}
            disabled={!isEditMode} 
            fullWidth
            onChange={(e) => handleInputChange('thyroide', e.target.value)}
            value={consultationData.thyroide} 
            multiline/>
              }

        </Typography>
        <Divider/>
        <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
          <TextFieldStyle label="Analyse"
            variant={variant}
            fullWidth
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('analyse', e.target.value)}
            value={consultationData.analyse} 
            multiline/>

          <TextFieldStyle label="Plan"
            variant={variant}
            fullWidth
            disabled={!isEditMode} 
            onChange={(e) => handleInputChange('plan', e.target.value)}
            value={consultationData.plan} 
            multiline/>

        </Typography>
      </Box>
    </>
}

GetConsultationSection.propTypes = {
  consultationData: PropTypes.object.isRequired,
  animalData: PropTypes.object.isRequired, 
  isEditMode: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired
};


export { GetEmptyConsultation, GetConsultationSection }