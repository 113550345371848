
import React, { useEffect } from 'react'
import { useState } from "react"
import TabSelector from "../../layout/TabSelector"
import { GetRequiredView } from "../../Navigator"
import { useTranslation } from 'react-i18next'
import { getEdit, getView } from '../../view/HeadersUtils'
import ReactGA from "react-ga4";
import { useDispatch } from 'react-redux'
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../../common/ClientSlice'
import { useLocation } from 'react-router-dom'
import { LoadProducts } from '../../headers/ProductsLoader'
import ViewProducts from './ViewProducts'

const ProductManager = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [itemToEdit, setItemToEdit] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState([getView(t), getEdit(t)])
  
  //Setup client header
  dispatch(setShowClearAnimalsSelector(false)) 
  dispatch(setShowClientsSelector(false))
  dispatch(setShowAnimalsSelector(false))
  dispatch(setAllowSelection(false))
  useEffect(() => {
    
    ReactGA.event("page_view", {"page_location": "produits", "page_title": "produits_" + currentView});
  }, [currentView]);

/*
  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }

  const saveCallback = (item) => {
    setItemToEdit(null)
    setItemNoToView(item.no)
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
  }*/
  return (
    <>
      <TabSelector 
        onViewChange={setCurrentView}
        pageTitle={t("product.description")}
        availableView={availViews}
        currentView={currentView}
        />
      <LoadProducts />
      { currentView === "view" && <ViewProducts /> }
    </>
  );
}


/*
        { currentView === "edit" && <CreateRx itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
        { currentView === "create" && <CreateRx saveCallback={saveCallback}/> }
*/
export default ProductManager