import React  from 'react';
import { FactureIcon } from '../common/IconFactory';
import InfoItemTooltip from '../card/InfoItemTooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { hasModuleBilling } from '../../services/AccessHub';

function ClientAction({clientData}) {
    const { t } = useTranslation();
    const navigateAction = useNavigate();
    const bill = hasModuleBilling() ? (  <FactureIcon data-testid='btnBill'
        title={t("button.createBill")}
        onClick={() => createBill(navigateAction, clientData)}
    />) : (<></>);

    return (<>
        {bill}
        <InfoItemTooltip
            noName={t('client.item.no')} 
            no={clientData.no} 
            updateDate={clientData.updateDate} 
            creationDate={clientData.creationDate} />
     </>)     
}

function navigateTo(navigateAction, target, clientDate) {
    navigateAction(target, { state: createNavigationData(clientDate) });
}

function createBill(navigateAction, clientDate) {
    navigateTo(navigateAction, '/factures', clientDate)
}

function createNavigationData(clientDate) {
    return {
      view: "create",
      selectedClient: clientDate
  }
}




export default ClientAction