import React  from 'react';
import { StandardTextField } from "./TextFieldStyle"
import { styled } from '@mui/material';


const EmailTextField = styled((props) => (
    <StandardTextField {...props}
        maxLength={128}
        slotProps={{
            input:{type: "email"}
        }}/>))();




  export default EmailTextField