import React  from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { emptyClient } from '../clients/BaseClient'
import { emptyAnimal } from '../animals/BaseAnimal'

const consultTypeMap = { "tel": "Consultation téléphonique",
                "video": "Consultation par Zoom"}
      
export const GetConsultTypeLabel = (consultType) => {
  return consultTypeMap[consultType]
}
                
const emptyEvent = {
    "inQc": true,
    "firstTime": true,
    "isActif": true, 
    "selectedDate": "",
    "firstname": "",
    "lastname": "",
    "phone": "",
    "address": "",
    "email": "",
    "petName": "",
    "petType": "",
    "petRace": "",
    "petBirthdate": "",
    "petSexe": "",
    "weight": 0,
    "weightLb": 0,
    "petColor": "",
    "emergency": false,
    "consultWhy": "",
    "consultMore": "",
    "consultType": "tel",
    "eventId": ""
  }


  function convertEventToClient(curEvent, newClientNo) {
    const newClient = {...emptyClient,
      no: newClientNo,
      name: curEvent.firstname + " " + curEvent.lastname,
      phone: curEvent.phone,
      addr1: curEvent.address,
      addr2: "",
      email: curEvent.email,
      //animals: [convertEventToAnimal(curEvent)]
    }

    return newClient
  }

  function convertEventToAnimal(curEvent, newAnimalNo, clientNo) {
    const newAnimal = {...emptyAnimal,
      no: newAnimalNo,
      clientNo: clientNo,
      isActif: true,
      name: curEvent.petName,
      birthdate: curEvent.petBirthdate,
      weight: curEvent.weight,
      type: curEvent.petType,
      race: curEvent.petRace,
      sexe: curEvent.petSexe,
      color: curEvent.petColor,
      micropuce: ""
    }
    
    return newAnimal
  }



export  const GetManageSection = ({alloNewClient, changeOptionHandle, eventData}) => {
  return (<>
    <b>Gestion de la demande</b>
      <form >
      <FormGroup sx={{ my: 2, mx: 2 }} >
        <FormControlLabel control={<Switch id="zoomLink"  defaultChecked onChange={(event) => changeOptionHandle("sendZoom", event.target.checked)} checked={eventData.sendZoom}/>} label="Générer un lien Zoom" />
        <FormControlLabel control={<Switch id="calendarLink" onChange={(event) => changeOptionHandle("sendCalendarEvent", event.target.checked)} checked={eventData.sendCalendarEvent}/>} label="Générer un événement calendrier pour le client" />
        <FormControlLabel control={<Switch id="sendEmail" onChange={(event) => changeOptionHandle("sendEmail", event.target.checked)} checked={eventData.sendEmail}/>} label="Envoyer un courriel avec les détails de la réservation" />
        <FormControlLabel disabled={!alloNewClient} control={<Switch id="newClient" onChange={(event) => changeOptionHandle("isNewClient", event.target.checked)} defaultChecked checked={eventData.isNewClient} />} label="Nouveau client" />
      </FormGroup>
      </form>
  </>)
}

  export {emptyEvent, convertEventToClient, convertEventToAnimal, consultTypeMap}


