
import React, { useEffect }  from 'react';
import { useState } from "react"
import TabSelector from "../../layout/TabSelector"
import { LayoutVaccine } from './LayoutVaccine';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../../services/authenticate';
import { LayoutRx } from './LayoutRx';
import { LayoutConsultation } from './LayoutConsultation';
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../../common/ClientSlice';
import { useDispatch } from 'react-redux';




const LayoutManager = () => {
    const {t} = useTranslation()
    const [currentView, setCurrentView] = useState("vaccine")
    // eslint-disable-next-line
    const [availViews, setAvailViews] = useState([{key: 0, title: t('layout.vaccine.title'), name: "vaccine"},
    {key: 1, title: t('layout.consultation.title'), name: "consultation"},
    {key: 2, title: t('layout.rx.title'), name: "rx"}
    ])

    const dispatch = useDispatch()
    //Setup client header
    dispatch(setShowClearAnimalsSelector(false)) 
    dispatch(setShowClientsSelector(false))
    dispatch(setShowAnimalsSelector(false))
    dispatch(setAllowSelection(false))

    useEffect(() => {
        ReactGA.event("page_view", {"page_location": "header", "client_id": GetClientKey(), "page_title": "layout_" + currentView});
    }, [currentView]);

      
    return (
        <>
            <TabSelector 
                onViewChange={setCurrentView}
                pageTitle={t('layout.title')}
                availableView={availViews}
                currentView={currentView}
                />
            { currentView === "vaccine" && <LayoutVaccine /> }
            { currentView === "rx" && <LayoutRx /> }
            { currentView === "consultation" && <LayoutConsultation /> }
        </>
        );
}


export default LayoutManager

