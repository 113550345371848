import React from 'react';
import { Box, Typography } from "@mui/material";
import logo from '../img/logo-nb.png'
import err403 from '../img/error/403.jpeg'
import ReactGA from "react-ga4";
import { useDispatch } from 'react-redux';
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../component/common/ClientSlice';

const Forbidden = () => {
    const dispatch = useDispatch()
    //Setup client header
    dispatch(setShowClearAnimalsSelector(false)) 
    dispatch(setShowClientsSelector(false))
    dispatch(setShowAnimalsSelector(false))
    dispatch(setAllowSelection(false))

    ReactGA.event("403");
    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
        <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }} >
              Erreur
        </Box>
        <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
            <Typography color='primary' variant="h4">
                Vous ne pouvez pas accéder à cette page
            </Typography>
            <img src={err403} width={600}/>
      </Box>
    </Box>
    )
}


export default Forbidden;