import React, { useEffect, useState }  from 'react';
import { Autocomplete, createFilterOptions, IconButton, Paper, Stack, TextField } from '@mui/material';
import { ProfitCalculatorDialog } from './ProfitCalculator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AddItemIconContent, DeleteIcon } from '../common/IconFactory';
import { useTranslation } from 'react-i18next';
import { StandardTextField } from '../input/TextFieldStyle';
import { LoadProducts } from '../headers/ProductsLoader';
import { useSelector } from 'react-redux';
import EditProductDialog from '../managment/products/EditProductDialog';
import { emptyItem, emptyItemDetail } from '../managment/products/BaseProduct';
import { v4 as uuid } from 'uuid';

const filter = createFilterOptions();

function FactureTable({items, handleInputChange, deleteItem, isEditMode}) {
  const { t } = useTranslation();

  const rows = isEditMode ? GetEditRows(items, handleInputChange, deleteItem) : GetViewRow(items)
  return (
    <TableContainer component={Paper}>
      <LoadProducts />
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell ><b>{t('billing.item.item')}</b></TableCell>
            <TableCell width="40" align="right"><b>{t('billing.item.qt')}</b></TableCell>
            <TableCell width="150" align="right"><b>{t('billing.item.amount')}</b></TableCell>
            <TableCell width="40" align="right"><b>{t('billing.item.total')}</b></TableCell>
            {isEditMode && <TableCell width="30"align="right"></TableCell> }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const GetEditRows = (items, handleInputChange, deleteItem) => {
    return (
        items.map((row, index) => (
          <GetEditRow 
            key={index}
            row={row} 
            index={index} 
            handleInputChange={handleInputChange} 
            deleteItem={deleteItem} />
        )
      )
    )
}




const GetEditRow = ({row, index, handleInputChange, deleteItem}) => {
  const [t] = useTranslation()
  const searchFieldName = "billingNameFr"
  const [openDialog, setOpenDialog] = useState(false);

  const productsList = useSelector(state => state.common.products)?.payload
  const [product, setProduct] = useState(null)
  const [wanabeProduct, setWanabeProduct] = useState(emptyItem)  

  useEffect(() => {
    if (product) {
      return handleInputChange(index, 'unitPrice', product.retailPrice)
    }
  }, [product]);

  useEffect(() => {
    if (productsList) {
      const notSelectedProduct = productsList.find((item) => item[searchFieldName] === row.name)
      if (notSelectedProduct && notSelectedProduct !== product) {
        handleProductSelected(null, notSelectedProduct)
      }
    }
  }, [productsList]);


  const txtOnBlur = (newValue) => {
    if (product && product[searchFieldName] !== newValue) {
      setProduct(null)        
    }
    const notSelectedProduct = productsList.find((item) => item[searchFieldName] === newValue)
    if (notSelectedProduct && notSelectedProduct !== product) {
      handleProductSelected(null, notSelectedProduct)
    }

    return handleInputChange(index, 'name', newValue)
  }

  const handleProductSelected = (event, newValue) => {
    const strNewValue = typeof newValue === 'string' ? newValue : newValue[searchFieldName]
    
    if (typeof newValue !== 'string' && newValue.no) {
      setProduct(newValue)
    } else {
      setProduct(null)
    }

     return handleInputChange(index, 'name', strNewValue)
  }



  //TODO : max length 60
  return (
      <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
          <TableCell component="th" scope="row">
            <Autocomplete
              freeSolo
              disableClearable
              onBlur={(event) => txtOnBlur(event.target.value)}
              onChange={(event, newValue) => handleProductSelected(event, newValue)}
              fullWidth
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={row?.name}
              options={productsList ? productsList.filter((item) => item.isActif === true) : []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option[searchFieldName];
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
        
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option[searchFieldName]);
                if (inputValue !== '' && !isExisting) {
                  filtered.push(inputValue);
                }
        
                return filtered;
              }}
              renderInput={(params) => 
                <Stack direction="row" alignItems={"center"}>
                <TextField 
                  {...params} 
                  sx={{paddingBottom: 2}}
                  fullWidth
                  variant="standard" 
                  label={t('billing.item.itemLong')}
                  />
                  { (!product && row?.name) &&
                    <IconButton sx={{height: '32px'}} onClick={() => setOpenDialog(true)}>
                      <AddItemIconContent />
                    </IconButton>
                  }
                  </Stack>
                  }
            />
            <EditProductDialog 
              open={openDialog} 
              onClose={() => setOpenDialog(false)}
              key={'createProductDialog-'+ index} 
              product={{...wanabeProduct, 
                no: uuid(),
                name: row.name,
                billingNameFr: row.name,
                billingNameEn: row.name,
                retailPrice: row.unitPrice}}
              isUpdate={false}
              onSave={() => setOpenDialog(false)}
              detail={emptyItemDetail}/>
            </TableCell>
          <TableCell component="th" align="right"> 
          <StandardTextField
              type="number"
              required
              testid={"itemQt-"+index}
              maxLength={4}
              slotProps={{
                htmlInput: {
                  min:0,
                  max:9999
                }
              }}
              value={row.quantite} variant="standard"
              onChange={(e) => handleInputChange(index, 'quantite', e.target.value)}
          />
          </TableCell>
          <TableCell component="th" align="right" >
            <Stack direction="row" alignItems={"center"}>
              <StandardTextField
                  type="number"
                  required
                  testid={"itemPrice-"+index}
                  maxLength={8}
                  slotProps={{
                    htmlInput: {
                      min:0.00,
                      max:9999.99,
                      step:"0.01",
                    }
                  }}
                  variant="standard"
                  value={row.unitPrice}
                  onBlur={ (e) => handleInputChange(index, 'unitPrice', parseFloat(e.target.value).toFixed(2))}
                  onChange={(e) => handleInputChange(index, 'unitPrice', e.target.value)}
                />
                <ProfitCalculatorDialog 
                  key={'dialogProfit'+ index} 
                  prixCourant={row.unitPrice}/>
              </Stack>
          </TableCell>
          <TableCell align="right" >{row.totalPrice}$</TableCell>
          <TableCell align="center"><DeleteIcon key={'btnDelete'+index} onClick={() => deleteItem(index)}/></TableCell>
      </TableRow>)
      
}





const GetViewRow = (items) => {
    return (
        items.map((row, index) => (
        <TableRow
            data-testid={"itemRow-"+index}
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" size='small' data-testid={"itemName-"+index}>
                {row.name}
            </TableCell>
            <TableCell align="right" size='small' data-testid={"itemQt-"+index}> 
                {row.quantite}
            </TableCell>
            <TableCell align="right" size='small' data-testid={"itemPrice-"+index}>{row.unitPrice}$</TableCell>
            <TableCell align="right" size='small' data-testid={"itemTotal-"+index}>{row.totalPrice}$</TableCell>
        </TableRow>)
        ))
}

export default FactureTable