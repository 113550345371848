/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {updateHeader} from './BaseHeader'
import { LoadHeader } from '../../headers/HeaderLoader';
import EmptyView from '../../view/EmptyView';
import { StandardTextField } from '../../input/TextFieldStyle';
import { DeleteIcon, InfoIconContent, TooltipIconButton } from '../../common/IconFactory';

export const emptyItem = {"order": null, "name": "",
  "nameOnBill": "",
  "rate": 0,
  "yourNumber": "",
  "cumulable": false}

const TAX_QUEBEC = [{...emptyItem, order: 1, name: "TPS", nameOnBill: "TPS", rate: 5, yourNumber: "", cumulable: false},
  {...emptyItem, order: 2, name: "TVQ", nameOnBill: "TVQ", rate: 9.975, yourNumber: "", cumulable: false}]

const TAX_CAD = [{...emptyItem, order: 1, name: "GST", nameOnBill: "GST", rate: 5, yourNumber: "", cumulable: false}]


export function TaxView({handleEdit}) {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const sliceHeader = useSelector(state => state.common.header)?.payload
  const [curHeader, setCurHeader] = useState();

  useEffect(() => {
    if (curHeader === undefined || curHeader === null) {
      setCurHeader(sliceHeader)
    }
  
  }, [sliceHeader]);

  if (curHeader === undefined || curHeader === null) {
    return (
      <EmptyView title={t('header.noSelection')}>
          <LoadHeader />
      </EmptyView>
    )
  }

  const deleteItem = (index) => {
    let newHeader = JSON.parse(JSON.stringify(curHeader))
    delete newHeader.preference.tax.items[index]
    newHeader.preference.tax.items = newHeader.preference.tax.items.filter( Array )
    
    setCurHeader(newHeader)
    return newHeader
  
  };

  const handleInputChange = (index, fieldName, value) => {
    let newItems = JSON.parse(JSON.stringify(curHeader))
    if (index !== null) {
      newItems.preference.tax.items[index][fieldName] = value
    } else {
      newItems.preference.tax[fieldName] = value
    }
    setCurHeader(newItems)
    return newItems
  };

  const addRow = (curHeader) => {
    let newItems = JSON.parse(JSON.stringify(curHeader))
    newItems.preference.tax.items = [...curHeader.preference.tax.items, {...emptyItem, order: curHeader.preference.tax.items.length + 1}]
    setCurHeader(newItems)
    return newItems
  };

  const replaceTax = (curHeader, taxList) => {
    let newItems = JSON.parse(JSON.stringify(curHeader))
    newItems.preference.tax.items = taxList
    setCurHeader(newItems)
  }


  const getView =(curHeader) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
        <LoadHeader />
        <div border="1">
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
          {t('header.tax.description')}
            </Typography>
            <form id="prefForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
              <FormControl>
                  <FormLabel >{t('header.tax.display')}</FormLabel>
                  <RadioGroup 
                      row
                      onChange={(e) => handleInputChange(null, 'display', e.target.value)}
                      value={curHeader.preference.tax?.display}>
                      <FormControlLabel value={true} control={<Radio />} label={t('button.yes')} />
                      <FormControlLabel value={false} control={<Radio />} label={t('button.no')} />
                  </RadioGroup>
              </FormControl>  

              <TaxTable curHeader={curHeader} handleInputChange={handleInputChange} deleteItem={deleteItem}/>
              
              
              <Button variant="contained" data-testid='taxQc'
                    onClick={() => {
                      replaceTax(curHeader, TAX_QUEBEC);
                  }}>
                Utiliser les taxes du Québec
              </Button>
              <Button variant="contained" data-testid='taxCad'
                    onClick={() => {
                      replaceTax(curHeader, TAX_CAD);
                  }}>
                Utiliser les taxes du Canada
              </Button>
              <CreateButtonSection addRowHandler={() => addRow(curHeader)}/>
            </form>
          </div>
        </Paper>
      )
  }

    return getView(curHeader)
}

const TaxTable = ({curHeader, handleInputChange, deleteItem}) => {
  const { t } = useTranslation();
  const variant = 'standard'
  return (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">{t('header.tax.item.order')}</TableCell>
            <TableCell align="right">{t('header.tax.item.name')}</TableCell>
            <TableCell align="right">{t('header.tax.item.nameOnBill')}</TableCell>
            <TableCell align="right">{t('header.tax.item.rate')}</TableCell>
            <TableCell align="right">{t('header.tax.item.yourNumber')}</TableCell>
            <TableCell align="right">
              <Box display="flex" alignItems="center">
                {t('header.tax.item.cumulableShort')} 
                <TooltipIconButton
                  title={t('header.tax.item.cumulable')}>
                  <InfoIconContent />
                </TooltipIconButton>
              </Box>
            </TableCell>
            <TableCell width="44px" align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {curHeader.preference.tax.items.map((item, index) => (
            <TableRow
              key={item.order}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">{item.order}</TableCell>
              <TableCell align="right">
                <StandardTextField label={t('header.tax.item.name')}
                    variant={variant}
                    fullWidth
                    required
                    maxLength={20}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    value={item.name} />
                </TableCell>
              <TableCell align="right">
                <StandardTextField label={t('header.tax.item.nameOnBill')}
                    variant={variant}
                    fullWidth
                    required
                    maxLength={20}
                    onChange={(e) => handleInputChange(index, 'nameOnBill', e.target.value)}
                    value={item.nameOnBill} />
              </TableCell>
              <TableCell align="right">
                <StandardTextField label={t('header.tax.item.rate')}
                    variant={variant}
                    fullWidth
                    type="number"
                    required
                    slotProps={{
                        htmlInput: {
                            min:1,
                            max:99,
                            step:0.001
                        }
                    }}
                    maxLength={5}
                    onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                    value={item.rate} />  
              </TableCell>
              <TableCell align="right">
                <StandardTextField label={t('header.tax.item.yourNumber')}
                    variant={variant}
                    fullWidth
                    maxLength={20}
                    onChange={(e) => handleInputChange(index, 'yourNumber', e.target.value)}
                    value={item.yourNumber} />
              </TableCell>
              <TableCell align="right">{item.cumulable}</TableCell>
              <TableCell align="center" padding='none'><DeleteIcon key={'btnDelete'+index} onClick={() => deleteItem(index)}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
}