
import {GetElement, UpdateElement, InsertElement, HardDeleteElement} from './S3Helper';

const ELEMENT_NAME = "headers"

const PRODUCTS_LIST_FILE = "products.json"
const DETAIL_PREFIX = "products/"

class Products {
   /*List() {
      return new ListElements(ELEMENT_NAME)
   }*/
 
   Get() {
      return new GetElement(ELEMENT_NAME, PRODUCTS_LIST_FILE)
   }
   GetDetail(key) {
      return new GetElement(ELEMENT_NAME, DETAIL_PREFIX + key)
   }

   UpdateDetail(key, detail) {
      return new UpdateElement(ELEMENT_NAME, DETAIL_PREFIX + key, detail)
   }

   Update(list) {
      return new UpdateElement(ELEMENT_NAME, PRODUCTS_LIST_FILE, list, false)
   }
   
   InsertDetail(key, detail) {
      return new InsertElement(ELEMENT_NAME, DETAIL_PREFIX + key, detail)
   }

   DeleteDetail(key) {
      return new HardDeleteElement(ELEMENT_NAME, DETAIL_PREFIX + key)
   }
 }

export default Products

