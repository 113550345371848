import { createSlice } from '@reduxjs/toolkit'
import { logger } from './Logger'


export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientNo: null,
    client: null,
    originalClientKey: null,
    animalNo: null,
    animal: null,
    animalsFilter: null,
    refreshClient: true,
    refreshAnimal: true,
    showAnimalsSelector: false,
    showClearAnimalsSelector: false,
    showClientsSelector: false,
    allowSelection: true
  },
  reducers: {
    setShowAnimalsSelector: (state, canShow) => {
      state.showAnimalsSelector = canShow
    },
    setShowClearAnimalsSelector: (state, canClear) => {
      state.showClearAnimalsSelector = canClear
    },
    setShowClientsSelector: (state, canShow) => {
      state.showClientsSelector = canShow
    },
    setAllowSelection: (state, allow) => {
      state.allowSelection = allow
    },
    setRefreshClient: (state, refresh) => {
      state.refreshClient = refresh
    },
    setRefreshAnimal: (state, refresh) => {
      state.refreshAnimal = refresh
    },
    setClient: (state, clientData) => {
      if (JSON.stringify(state.client) !== JSON.stringify(clientData)) {
        logger.debug("store : setClient", clientData)
        state.client = clientData
        if (clientData.payload) {
          state.clientNo = clientData.payload.clientNo
          state.originalClientKey = clientData.payload.key
        } else {
          state.clientNo = null
          state.originalClientKey = null
        }
        state.animal = null
      }
    },
    setAnimal: (state, animalData) => {
      if (JSON.stringify(state.animal) !== JSON.stringify(animalData)) {
        logger.debug("store : setAnimal, ", animalData)
        state.animal = animalData
      }
    },
    setAnimalsFilter: (state, animalData) => {
      if (JSON.stringify(state.animalsFilter) !== JSON.stringify(animalData)) {
        logger.debug("store : setAnimal, ", animalData)
        state.animalsFilter = animalData
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setClient, setAnimal, setAnimalsFilter, setRefreshClient, setRefreshAnimal, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector, setAllowSelection } = clientSlice.actions

export default clientSlice.reducer