import jsPDF from 'jspdf';
import { messageStore } from './common/InfoMessage';
import { logger } from './common/Logger';

const handleDownload = (setIsPrintMode, pdfContentRef, pdfName) => {
    //this.handlePrintMode(true)
    setIsPrintMode(true)
    SavePDF(pdfContentRef.current, pdfName, () => {messageStore.sendMessage("Pdf créé avec succès", "info")})
    .then(() =>  setIsPrintMode(false))
  }

function SavePDF(currentRef, fileName, callback) {
    const originalWidth = currentRef.width
    currentRef.width = 780
    logger.debug("Will save : ", fileName)
  
    return generatePdf(currentRef, fileName, callback).then(() => {
        currentRef.width = originalWidth
    })
}


function generatePdf(htmlElement, fileName, callback) {
    let jsPdf = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: 'letter',
            compressPdf: true
          });
    //var htmlElement = document.getElementById('doc-target');
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
        callback: function (jsPdf) {
            jsPdf.save(fileName, { returnPromise: true });
            //window.open(jsPdf.output('bloburl'));
            if (callback) {
                callback(fileName, jsPdf.output())
            }
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [20, 20, 20, 20],
        autoPaging: 'text', //||  "slice" |
        html2canvas: {
            allowTaint: true,
            dpi: 72,
            letterRendering: true,
            logging: false,
            scale: .73,
            windowWidth: 780,
            width: 780,
           onclone: (doc) => { //Hack de la mort qui tue
                // doc returns the entire document, not just ele
                let target = doc.querySelector("#root");
                let images = target.querySelectorAll("img");
    
                let targetRect = target.getBoundingClientRect();
    
                images.forEach((img) => {
                    let rect = img.getBoundingClientRect();
                    jsPdf.addImage(img, "JPEG", targetRect.x, targetRect.y, 1, 1);
                    img.remove();
                });
            }
        }
        /*, Do nothing
        image: {
            type: 'jped',
            quality: 10
        }*/
            
    };

    return jsPdf.html(htmlElement, opt);

}



export {SavePDF, handleDownload}