import React from "react";
import { Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import "./PlanCard.css"

const PlanCard = ({ title, description, price, number, priceDescription,logo, pricePeriode, shouldBeDisplay, haveIt, disabled }) => {

  if (shouldBeDisplay === false) {
    return (<></>)
  }

  const cardClass = haveIt ? "plan-card selected" : "plan-card"

  return (
    <Card className={cardClass} sx={{ maxWidth: '220px', my: 1, mx: 1 }}
    onMouseOver={(e) => disabled || haveIt ? '' : e.currentTarget.classList.add('hover')}
    onMouseOut={(e) => e.currentTarget.classList.remove('hover')}>
      
      <CardHeader
        title={<>{logo} {title}</>}
        subheader=""
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p" align="left">
          {description}
        </Typography>
        {price && 
          <Typography variant="h5" component="h2">
            {price} ${pricePeriode}
          </Typography>
        }
        <Typography variant="h5" component="h2">
          {number}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {priceDescription}
        </Typography>
      </CardContent>
      <CardActions>
       
      </CardActions>
    </Card>
  );
}

export default PlanCard