import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import {updateHeader} from '../cie/BaseHeader'
import { LoadHeader } from '../../headers/HeaderLoader';
import { StandardTextField } from '../../input/TextFieldStyle';

export function LayoutVaccine() {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const sliceHeader = useSelector(state => state.common.header)?.payload
  const [curHeader, setCurHeader] = useState();

  useEffect(() => {
    if (curHeader === undefined || curHeader === null) {
      setCurHeader(sliceHeader)
    }
  }, [sliceHeader]);

  const handleInputChange = (index, fieldName, value) => {
      let newHeader = JSON.parse(JSON.stringify(curHeader));
      newHeader.layout.vaccine.items[index][fieldName] = value;
      setCurHeader(newHeader);
  };

  const getView =(curHeader) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
        <LoadHeader />
        <div border="1" >
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            {t('layout.vaccine.title')}
            </Typography>
            <form id="cieForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
              <Box sx={{ my: 2, mx: 4 }} >
              <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                {t('layout.defaultValues')}
              </Typography>
              
              <StandardTextField label={t('vaccine.item.vaccineLong')}
                    fullWidth
                    variant="outlined"
                    required
                    maxLength={50}
                    onChange={(e) => handleInputChange(0, 'name', e.target.value)}
                    value={curHeader.layout.vaccine.items[0].name} />
              <StandardTextField label={t('vaccine.item.nextDate')}
                  type="number"
                  required
                  fullWidth
                  maxLength={3}
                  slotProps={{
                    htmlInput: {
                      min:0,
                      max:999,
                    }
                  }}
                  variant="standard"
                  value={curHeader.layout.vaccine.items[0].rappel}
                  onChange={(e) => handleInputChange(0, 'rappel', Number(e.target.value))}
                />
                
                <CreateButtonSection />
              </Box>
            </form>
          </div>
        </Paper>
      )
  }

  if (curHeader?.layout === undefined || curHeader?.layout === null) {
    return (<></>)
  }
  return getView(curHeader)
}

