import Animals from "../../../api/Animals";
import { setAnimal } from "../../common/ClientSlice";
import { messageStore } from "../../common/InfoMessage";
import { addLoadingCount, subLoadingCount } from "../../common/LoadingSlice";
import { logger } from '../../common/Logger';
import { formatToSystemDate } from "../../date/DateUtils";

export const deleteItem = (itemArrayName, itemToDelete, animal, dispatch, t) => {
  logger.debug("Deleting item in animal : ", itemToDelete)
  return new Animals().Get(animal.key).then((animalReload) => {
    logger.trace("Relead aminal : ", animal, animalReload)
    var animalUpdate = JSON.parse(JSON.stringify(animalReload))
    const objIndex = animalReload[itemArrayName].findIndex(obj => obj.no === itemToDelete.no);
    delete animalUpdate[itemArrayName][objIndex]
    animalUpdate[itemArrayName] = animalUpdate[itemArrayName].filter(n => n)
    logger.trace("Update aminal : ", animalUpdate)
    new Animals().Update(animalUpdate, false).then((result) => {
      if (result.status === 200) {
        new Animals().Get(animalUpdate.key).then(setAnimal).then(dispatch)
        messageStore.sendMessage(t('message.deleteSuccess'))
      } else {
        messageStore.sendMessage(result.statusText, "error", t('message.deleteError'))
      }
  })
  })
}
  

export const addItemAnimal = (animalKey, itemArrayName, newItem, saveCallback, dispatch, t, tContext) => {
  const item = {...newItem, creationDate: formatToSystemDate(new Date())} 

  logger.debug("Add item in animal : ", item)
  dispatch(addLoadingCount())
  return new Animals().Get(animalKey).then((animalReload) => {
    animalReload[itemArrayName] = animalReload[itemArrayName] ? [...animalReload[itemArrayName], item] : [item]
    updateAnimal(animalReload, item, false, saveCallback, dispatch, t, tContext)
    .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
    .then(() => dispatch(subLoadingCount()))
  })
}

export const updateItemAnimal = (animalKey, itemArrayName, newItem, saveCallback, dispatch, t, tContext) => {
  const item = {...newItem, updateDate: formatToSystemDate(new Date())} 

  logger.debug("Add item in animal : ", item)
  dispatch(addLoadingCount())
  return new Animals().Get(animalKey).then((animalReload) => {
    const objIndex = animalReload[itemArrayName].findIndex(obj => obj.no === item.no);
    animalReload[itemArrayName][objIndex] = item

    updateAnimal(animalReload, item, true, saveCallback, dispatch, t, tContext)
    .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
    .then(() => dispatch(subLoadingCount()))
  })
}


export const updateAnimal = (animalUpdate, newItem, isUpdateItem, saveCallback, dispatch, t, tContext) => {
  logger.debug("Update item in animal : ", newItem)
  return new Animals().Update(animalUpdate, false).then((result) => {
    if (result.status === 200) {
      new Animals().Get(animalUpdate.key).then(setAnimal).then(dispatch).then(() => {saveCallback(newItem)})
      if (isUpdateItem) {
        messageStore.sendMessage(t(tContext + '.updateSuccess'))
      } else {
        messageStore.sendMessage(t(tContext + '.saveSuccess'))
      }
    } else {
      messageStore.sendMessage(result.statusText, "error","Erreur lors de la création")
    }
  })
}