import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import EmptyView from '../view/EmptyView';
import { emptyAnimal } from './BaseAnimal';
import { AnimalSection } from './AnimalSection';
import Animals from '../../api/Animals';
import { messageStore } from '../common/InfoMessage';
import { CreateButtonSection } from '../view/CreateButtonSection';
import { Typography } from '@mui/material';
import { formatToSystemDate, monthDiff } from '../date/DateUtils';
import { useTranslation } from 'react-i18next';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { v4 as uuid } from 'uuid';
import { handleAction } from '../../services/AccessHub';
import { logger } from '../common/Logger';

const birthdate = 'birthdate'
const ageInMonth = 'monthAge'

const calculateAgeInMonth = (date) => {
  return monthDiff(new Date(date), new Date())
}

const calculateBirthdate = (ageInMonth) => {
  var curDate = new Date()
  curDate.setMonth(curDate.getMonth() - ageInMonth)
  return formatToSystemDate(curDate)
}

export function CreateAnimal({itemToEdit, saveCallback}) {
  const { t } = useTranslation();
  const isUpdate = (itemToEdit)
  const dispatch = useDispatch();
  const curClient = useSelector(state => state.client.client) 
  const [animal, setAnimal] = useState(isUpdate ? itemToEdit : emptyAnimal);
  // eslint-disable-next-line
  const [originalAnimalKey, setOriginalAnimalKey] = useState(isUpdate ? itemToEdit.key : null);

  React.useEffect(() => {
    handleSetNo();
  }, [animal, isUpdate]);
  
  const handleSetNo = () => {
    if (!isUpdate && (animal.no === "" || animal.no === undefined)) {
      logger.debug("Need new no")
      handleInputChange("no", uuid())
    }
  }

  logger.trace("curAnimal : ", animal)
  
  // Fonction pour mettre à jour les valeurs des champs
  const handleInputChange = (fieldName, value) => {
    if (animal[fieldName] !== value) {
      if (fieldName === birthdate) {
        setAnimal({...animal,
          [fieldName]: value,
          ageInMonth: calculateAgeInMonth(value)
        })
      } else if (fieldName === ageInMonth) {
        setAnimal({...animal,
          [fieldName]: value,
          birthdate: calculateBirthdate(value)
        })
      } else {
        setAnimal({...animal,
          [fieldName]: value
        })
      }
    }
  };

  const internalHandleSave = async (event) => {
    event.preventDefault();
    var newAnimal = JSON.parse(JSON.stringify(animal))
    newAnimal.clientNo = curClient.payload.no
    const api = new Animals()

    dispatch(addLoadingCount())
    const newGeneratedKey = api.generateNewKey(newAnimal)
    if (isUpdate && originalAnimalKey && originalAnimalKey !== newGeneratedKey) {
      return renameAnimal(newAnimal, originalAnimalKey, saveCallback, t)
      .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      .then(() => dispatch(subLoadingCount()))
    } else if (isUpdate) {
        return updateAnimal(newAnimal, saveCallback, t)
        .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
        .then(() => dispatch(subLoadingCount()))
    } else {
      handleAction("animal", dispatch)
      return createAnimal(newAnimal, saveCallback, t)
      .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      .then(() => dispatch(subLoadingCount()))
    }
  }


    const getView =(animal) => {
      return (<Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
          
                
        <form id="clientForm"  onSubmit={internalHandleSave}>
          
    
          <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
            {t('animal.mainTitle')}          
          </Typography> 
          <AnimalSection 
            animalData={animal}
            isEditMode={true} 
            handleInputChange={handleInputChange}/> 
        <br/>
        <CreateButtonSection />
    </form>
    </Paper>)
    }

    if (curClient === undefined || curClient === null) {
      return (<EmptyView title={t('client.noSelection')}  />)
    }

    return getView(animal)
}


const createAnimal = (animal, saveCallback, t) => {
  logger.debug("Create animal")
  return new Animals().Insert(animal).then((result) => {
    if (result.status === 200) {
      if (saveCallback) {
        saveCallback(result.data)
      }
      messageStore.sendMessage(t('animal.saveSuccess'))
    } else {
      messageStore.sendMessage(result.statusText, "error", t('saveError'))
    }
  })
}

const updateAnimal = (animal, saveCallback, t) => {
   logger.debug("Update animal")
   return new Animals().Update(animal).then((result) => {
    if (result.status === 200) {
      if (saveCallback) {
        saveCallback(result.data)
      }
      messageStore.sendMessage(t('animal.updateSuccess'))
    } else {
      messageStore.sendMessage(result.statusText, "error", t('saveError'))
    }
  })
}

const renameAnimal = (animal, originalAnimalKey, saveCallback, t) => {
  logger.debug("Required client rename")
  const api = new Animals()
  return createAnimal(animal, saveCallback, t)
  .then(() => api.deleteByKey(originalAnimalKey))
  .then(() => window.location.reload())

}