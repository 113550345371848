
import pino from 'pino';
import env from '../../env/env.json';
//logging

export const logger = pino({
    enabled: true,
    formatters: {
      level: (label) => {
        return { level: label };
      },
    },
    level: env.logging || 'error',
    // eslint-disable-next-line no-undef
    name: process.env.LOGGER_NAME,
    redact: {
      paths: ['email', 'password', 'token'],
    },
    // https://github.com/pinojs/pino/issues/674
    timestamp: pino.stdTimeFunctions.isoTime,
  });