import React, { useEffect }  from 'react';
import TabSelector from "../layout/TabSelector"
import { ViewBill } from "./ViewBill"
import { CreateBill } from "./CreateBill"
import { useState } from "react"
import { GetRequiredView } from "../Navigator"
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../view/HeadersUtils';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../services/authenticate';
import { LoadActionsLimit } from '../headers/ActionsLimitLoader';
import Clients from '../../api/Clients';
import { setAllowSelection, setClient, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../common/ClientSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const BillManager = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const location = useLocation();
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(location.state?.itemNoToView)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))
  

  //Setup client header
  dispatch(setShowClearAnimalsSelector(true)) 
  dispatch(setShowClientsSelector(true))

  useEffect(() => {
    dispatch(setShowAnimalsSelector(currentView !== "view"))
    dispatch(setAllowSelection(currentView !== "edit"))

    ReactGA.event("page_view", {"page_location": "bills", "client_id": GetClientKey(), "page_title": "bills_" + currentView});
  }, [currentView]);


  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }

  const saveCallback = (clientKey, item) => {
    new Clients().Get(clientKey)
    .then(setClient)
    .then(dispatch)
    .then(() => {
    setItemToEdit(null)
    if (item) {
      setItemNoToView(item.no)
    }
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
    })
  }
  return (
      <>
      <TabSelector 
        onViewChange={setCurrentView}
        pageTitle={t('billing.title')}
        availableView={availViews}
        currentView={currentView}
        />
         <LoadActionsLimit />
        { currentView === "view" && <ViewBill itemNo={itemNoToView} handleEdit={handleEdit} saveCallback={saveCallback}/> }
        { currentView === "edit" && <CreateBill itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
        { currentView === "create" && <CreateBill saveCallback={saveCallback} /> }
      </>
    );
}

export default BillManager