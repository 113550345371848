
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import EmptyView from '../../view/EmptyView';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import { GetEmptyConsultation, GetConsultationSection } from './BaseConsultation';
import DayPicker from '../../date/DatePicker';
import ConsultationSelector from './ConsultationSelector';
import { addItemAnimal, updateItemAnimal } from '../common/ItemUtils';
import { useTranslation } from 'react-i18next';
import { LoadHeader } from '../../headers/HeaderLoader';
import { canCreateConsultation, handleAction } from '../../../services/AccessHub';
import NoMoreActionView from '../../view/NoMoreActionView';
import { logger } from '../../common/Logger';

export function CreateConsultation({itemToEdit, saveCallback}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [consultation, setConsultation] = useState(isUpdate ? itemToEdit : GetEmptyConsultation(header?.payload?.layout));
  const limits = useSelector(state => state.common.limits)?.payload

  React.useEffect(() => {
    handleSetNo();
  }, [consultation, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && consultation.no === "" || consultation.no === undefined) {
      logger.debug("Need new no")
      handleRootChange("no", uuid())
    }
  }

    logger.debug("curConsultation : ", consultation)
    
    // Fonction pour mettre à jour les valeurs des champs
    const handleInputChange = (fieldName, value) => {
      if (consultation.consultation[fieldName] !== value) {  
        logger.debug("current consultation : ", consultation)
        var newConsultation = JSON.parse(JSON.stringify(consultation))
        newConsultation.consultation[fieldName] = value;
        setConsultation(newConsultation)
      }
    };
    const handleRootChange = (fieldName, value) => {
      if (consultation.date !== value) {  
        var newConsultation = JSON.parse(JSON.stringify(consultation))
        newConsultation[fieldName] = value;
        setConsultation(newConsultation)
      }
    };

    const internalHandleSave = async (event) => {
        event.preventDefault();

        if (isUpdate) {
          return updateItemAnimal(curAnimal.payload.key, "consultations", consultation, saveCallback, dispatch, t, "consultation")
        } else {
          handleAction("consultation", dispatch)
          return addItemAnimal(curAnimal.payload.key, "consultations", consultation, saveCallback, dispatch, t, "consultation")
        }
    }


      const getView =(header, curClient, curAnimal, consultation) => {
        return (
        <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
          <LoadHeader />
          { isUpdate &&
            <ConsultationSelector animal={curAnimal} consultation={consultation} disable={true}/>
          }      
          <form id="consultationForm"  onSubmit={internalHandleSave}>
          <FlatHeaderRenderer 
            headerData={header} 
            clientData={curClient} 
            animalData={curAnimal}
            extraData={{
                  "date": 
                    <DayPicker 
                      value={ consultation.date}
                      inputChange={ (e) => handleRootChange("date", convertToSystemDateFormat(e))}
                      />}}/>
      
          <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" variant="h5">
              Détails de la consultation
          </Typography>
          <GetConsultationSection 
            consultationData={consultation.consultation} 
            animalData={curAnimal}
            isEditMode={true} handleInputChange={handleInputChange}/>
          <br/>
          <CreateButtonSection />
      </form>
      </Paper>)
      }

      if (!canCreateConsultation(limits)) {
        return (<NoMoreActionView/>)
      }

      if ((consultation === undefined || consultation === null) || (curAnimal === undefined || curAnimal === null)) {
        return (<EmptyView title={t('animal.noSelection')} />)
      }

      return getView(header?.payload, curClient?.payload, curAnimal?.payload, consultation)
}

