import React  from 'react';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import {  fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import userpool from '../component/userpool';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {userStore} from '../component/common/Session'
import env from '../env/env.json';
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import avCindy from '../img/avatar/cindy.jpg'
import { Avatar } from '@mui/material';
import { logger } from '../component/common/Logger';


function GetUserRoles() {
    if (userpool.getCurrentUser() === null) {
        return []
    }
    //validateRefresh() Sure?
     
    return userStore.getIdToken()?.payload['cognito:groups']
}

function HasRole(role) {
    const roles = GetUserRoles()
    return roles?.findIndex((curRole) => curRole === role) > -1
}


function GetUserIcon(username) {
    if (!username) {
        return <NoAccountsIcon/>
    }
    const un = username
    if (un === "cindy.lefebvre@gmail.com") {
        
        return <Avatar src={avCindy}  />
    } else if (un === "compagnat") {
        return <AdminPanelSettingsIcon/>
    }

    return <PersonOutlineIcon />
}


const Authenticate=(email,Password)=>{
    return new Promise((resolve,reject)=>{
        const user=new CognitoUser({
            Username:email,
            Pool:userpool
        });

        const authDetails= new AuthenticationDetails({
            Username:email,
            Password
        });
        user.authenticateUser(authDetails,{
            onSuccess:(result)=>{
                logger.debug("login successful");
                resolve(result)               
            },
            onFailure:(err)=>{
                logger.debug("login failed",err);
                reject(err);
            }, 
            newPasswordRequired: userAttr => {
                logger.debug("Need to create new password");
                resolve({isFirstLoging: true, user: user, userAttr: userAttr})
            }
        });
    });
};


const changePassword = (oldPassword, newPassword, email) => {
    return new Promise((resolve,reject)=>{
        const user=new CognitoUser({
            Username:email,
            Pool:userpool
        });

        const authDetails= new AuthenticationDetails({
            Username: email,
            Password: oldPassword
        });

        //We must have a session to perform the change password
        // eslint-disable-next-line
        user.getSession((error, session) => {
            if (error !== null) {
                //If no session, we need to authenticate the user
                user.authenticateUser(authDetails,{
                    onSuccess:(result)=>{
                        reject(result);              
                    },
                    onFailure:(err)=>{
                        logger.debug("login failed",err);
                        reject(err);
                    }, 
                    newPasswordRequired: userAttr => {
                        firstTimeChangePws(user, userAttr, oldPassword, newPassword, reject, resolve)
                    }
                });
            } else {
                //Else, we have a session
                user.changePassword(oldPassword, newPassword, (err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                });
            }
        }
        ); 
    });
  };


  const firstTimeChangePws = (user, userAttr, oldPassword, newPassword, reject, resolve) => {
    //See to not need using userAttr
    user.completeNewPasswordChallenge(newPassword, {}, { onSuccess: result => {
        logger.debug("Password changed")
        resolve(result)
      },
      // eslint-disable-next-line
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.error("newPasswordRequired in the firstTimeChangePws. Impossible...")

      },
      onFailure: err => {
        reject(err)
      }
    });
  }



const GetAwsCreds = async (region) => {
    const idToken = GetAccessToken()


    const cognitoidentity = new CognitoIdentityClient({
        credentials : fromCognitoIdentityPool({
            client : new CognitoIdentityClient({"region": env.aws_cognito_region}),
            identityPoolId : env.aws_cognito_identity_pool_id,
            "region": env.aws_cognito_region,
            logins: {
              ["cognito-idp.ca-central-1.amazonaws.com/" + env.REACT_APP_USER_POOL_ID] : idToken
            }
         }),
    });
    const credentials = await cognitoidentity.config.credentials()

    credentials.region = region


    return (
        {
            "credentials": {
                "accessKeyId": credentials.accessKeyId,
                "secretAccessKey": credentials.secretAccessKey,
                "sessionToken": credentials.sessionToken,
                "expiration": credentials.expiration
            },
            "region": region
    })
}

function GetAccessToken() {
    if (userpool.getCurrentUser() === null) {
        return null
    }
    validateRefresh()
     
  
    return userStore.getIdToken()?.jwtToken
}

function GetClientKey() {
    if (userpool.getCurrentUser() === null) {
        return null
    }
    validateRefresh()
     
    return userStore.getIdToken()?.payload["custom:clientKey"]
}

function GetSubClientKey() {
    if (userpool.getCurrentUser() === null) {
        return null
    }
    validateRefresh()
     
    return userStore.getIdToken()?.payload["custom:subClientKey"]
}


function validateRefresh() {
    const token = userStore.getIdToken()  
    if (token !== null && token !== undefined) {
      //Validate current token
      if (new Date().getTime()/1000 > token.payload.exp) {
        userStore.setSession(null)
        fetchNewAccessToken()
      }
    } else {
        fetchNewAccessToken()
    }
}

  


function fetchNewAccessToken() {
    //logger.debug("Fetch new token")
    userpool.getCurrentUser()?.getSession((error, session) => {
        if (error !== null) {
          userStore.setSession(null)
          console.error("fetchNewAccessToken : ", error)
        } else {
          logger.debug("New token define!")
          userStore.setSession(session)
        }
      });
    return userStore.getIdToken()
}
  

export {GetClientKey, GetSubClientKey, GetAwsCreds, Authenticate, changePassword, GetUserIcon, GetAccessToken, HasRole};
