import { React} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { useEffect } from "react";
import { setProducts } from "../common/CommonSlice";
import { messageStore } from '../common/InfoMessage';
import Products from '../../api/Products';

export const LoadProducts = () => {
    const dispatch = useDispatch()
    const mustReload =  useSelector(state => state.common.reloadProducts)
    const curProducts = useSelector(state => state.common.products)
    useEffect(()=>{
       if (mustReload) {
            dispatch(addLoadingCount())
            new Products().Get()
              .then(setProducts)
              .then(dispatch)
              .catch((error) => {
                  console.error("Error loading products : ", error)
                  messageStore.sendMessage("Error loading products", "error")
                  dispatch(setProducts([]))
              })
              .then(subLoadingCount)
              .then(dispatch)              
          }
      },[curProducts, mustReload]);
    return (<></>)
}
