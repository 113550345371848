import React  from 'react';
import {ViewEvents} from './ViewEvents';
import TabSelector from "../layout/TabSelector";
import { useState } from "react";
import { CreateEvents } from './CreateEvents';
import { useDispatch } from 'react-redux';
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../common/ClientSlice';

const EventsManager = () => {
  const [currentView, setCurrentView] = useState("view")
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [availViews, setAvailViews] = useState([{key: 0, title: "Consultation à confirmer", name: "view"},
    {key: 1, title: "Consultation ad-hoc", name: "create"}])
    
  //Setup client header
  dispatch(setShowClearAnimalsSelector(false)) 
  dispatch(setShowClientsSelector(currentView === "create"))
  dispatch(setShowAnimalsSelector(currentView === "create"))
  dispatch(setAllowSelection(true))


  const saveCallback = () => {
    //setItemToEdit(null)
    // setItemNoToView(item.no)
    setCurrentView("view")
  }

  return (
      <>
        <TabSelector 
          onViewChange={setCurrentView}
          pageTitle="Gestion des demandes de consultation"
          availableView={availViews}
          currentView={currentView}
          />
        { currentView === "view" && <ViewEvents /> }
        { currentView === "create" && <CreateEvents saveCallback={saveCallback}/> }
      </>
    );
}


export default EventsManager