import { formatToSystemDate } from '../../date/DateUtils';
const emptyVaccin = {"isActif": true, 
    "date": formatToSystemDate(new Date()),
    "items":[], 
    "no": "",
    "consultationNo": ""
}

const GetEmptyItem = (layout) => {
    const defItem = layout?.vaccine?.items[0]
    //TODO compute : defItem.rappel
    return {"name": defItem?.name, "effective": formatToSystemDate(new Date()) ,
        "rappel": formatToSystemDate(
            culculateRappel(new Date(), defItem?.rappel || 0))}
}

const culculateRappel = (originalDate, month) => { 
    return originalDate.setMonth(originalDate.getMonth() + month)
}

export { emptyVaccin, GetEmptyItem , culculateRappel}