import React from "react";
import { Autocomplete, Stack, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import InfoItemTooltip from "../card/InfoItemTooltip";

const BillSelector = ({client, bill, setBill, disable}) => {

  useEffect(() => {
    if ((bill === undefined || bill === null) && client?.bills?.length > 0) {
      setBill(client.bills[client.bills.length - 1])
    }
  }, [client]);

  

  const intenalOnChange = (event, newValue) => {
    ReactGA.event("select_item", {"item_list_name": "bill", "items": [{"item_id" : newValue.no, "item_name" : selectOptionTitre(newValue), "item_category": client.no }] });
    setBill(newValue)
  }
  const { t } = useTranslation();
  return (
    <Stack direction="row" sx={{alignItems: "center"}}>
      <Autocomplete
        aria-label="lstBills"
        disabled={disable}
        disablePortal
        disableClearable
        onChange={intenalOnChange}
        sx={{ maxWidth: 500, width: '100%', my: 5, mx: 2 }}
        value={bill}
        options={client?.bills ? client.bills.toReversed() : []}
        getOptionLabel={selectOptionTitre}
        renderInput={(params) => <TextField {...params} label={t('billing.selectorTitle')}/>}
      />
      { bill &&
        <InfoItemTooltip
            noName={t('billing.item.no')} 
            no={bill?.no} 
            updateDate={bill?.updateDate} 
            creationDate={bill?.creationDate} />
      }
    </Stack>)
  }
  
  const selectOptionTitre = (option) => {
    var paidLabel = option.isPaid ? "" : " - Non payé" 
    paidLabel = "#" + option.no + "  "+ option.date + paidLabel
    paidLabel = option.animal?.name ? paidLabel + " (" + option.animal.name + ")" : paidLabel

    return paidLabel
  }

  BillSelector.propTypes = {
    client: PropTypes.object,
    bill: PropTypes.object,
    setBill: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };
  

  export default BillSelector