
import React  from 'react';
import { useState } from "react"

import {ViewEventConfirmation} from './ViewEventConfirmation'
import TabSelector from "../layout/TabSelector"
import { useDispatch } from 'react-redux';
import { setAllowSelection, setShowAnimalsSelector, setShowClearAnimalsSelector, setShowClientsSelector } from '../common/ClientSlice';

const EventsConfirmManager = () => {
    const [currentView, setCurrentView] = useState("view")
    // eslint-disable-next-line no-unused-vars
    const [availViews, setAvailViews] = useState([{key: 0, title: "Visualisation", name: "view"}])
    const dispatch = useDispatch()
    //Setup client header
    dispatch(setShowClearAnimalsSelector(false)) 
    dispatch(setShowClientsSelector(false))
    dispatch(setShowAnimalsSelector(false))
    dispatch(setAllowSelection(false))

    return (
        <>
            <TabSelector 
                onViewChange={setCurrentView}
                pageTitle="Constultation confirmée"
                availableView={availViews}
                currentView={currentView}
                />
        <ViewEventConfirmation />
       </>
      );
}


export default EventsConfirmManager

