
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { GetManageSection } from './BaseEvent'
import { useSelector } from 'react-redux';
import { messageStore } from '../common/InfoMessage';
import EmptyView from '../view/EmptyView';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppBar, Button, Grid, Toolbar } from '@mui/material';
import { createEventConfirm, emptyEventConfirm } from '../eventsConfirm/BaseEventConfirm';
import { CalendarUpdate } from '../../api/Calendar';
import { GetClientSection } from '../clients/BaseClient';
import { AnimalSection } from '../animals/AnimalSection';
import { convertToSystemDateHourFormat } from '../date/DateUtils';
import { useTranslation } from 'react-i18next';
import { logger } from '../common/Logger';

export function CreateEvents() {
  const { t } = useTranslation();
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [events, setEvents] = useState(emptyEventConfirm);

  
  // Fonction pour mettre à jour les valeurs des champs
  const handleInputChange = (fieldName, value) => {
    var newEvents = JSON.parse(JSON.stringify(events)) 
    if (newEvents[fieldName] !== value) {
      newEvents[fieldName] = value
      setEvents(newEvents)
    }
  };

  const changeDate = (event) => {
    const dtFormat = convertToSystemDateHourFormat(event)
    logger.debug("changeDate", dtFormat)
    handleInputChange("selectedDate", dtFormat)
  }


  const getView =(curClient, curAnimal, events) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <GetClientSection clientData={curClient} isEditMode={false} handleInputChange={()=> {}}/>
      
      <AnimalSection animalData={curAnimal} isEditMode={false}/>
          
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} 
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
            Option pour la consultation
          </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>  

          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
            <b>Date sélectionné : </b> 
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker onChange={changeDate}  />
            </LocalizationProvider>
          </Typography>
          
          <GetManageSection 
            alloNewClient={false}
            changeOptionHandle={handleInputChange}
            eventData={events} />
          

            <Button variant="contained"
              onClick={() => {createAndSaveConfirmEvent(curClient, curAnimal, events)}} >Créer la demande</Button>
        </Paper>
    );
  }
  

  if (curAnimal === undefined || curAnimal === null) {
    return (<EmptyView title={t('animal.noSelection')} />)
  }

  return getView(curClient?.payload, curAnimal?.payload, events)
}


const getOriginalEvent = (events, client, animal) => {
  const newEvent = JSON.parse(JSON.stringify(events))
  newEvent["selectedSystemDate"] = events.selectedDate
  
  newEvent["eventId"] = ""
  newEvent["selectedDate"] = events.selectedDate
  newEvent["firstname"] = client.name
  newEvent["lastname"] = ""
  newEvent["phone"] = client.phone
  newEvent["address"] = client.addr1
  newEvent["email"] = client.email
  newEvent["petName"] = animal.name
  newEvent["petType"] = animal.type
  newEvent["petBirthdate"] = animal.birthdate
  newEvent["weight"] = animal.weight
  logger.debug("Fake event : ", newEvent)
  return newEvent
}



const createAndSaveConfirmEvent = (client, animal, events) => {

    const clientData = JSON.parse(JSON.stringify(client))
    clientData.animal = animal
    clientData.animals = []
    
    const eventConfirmData = createEventConfirm("AD", 
      getOriginalEvent(events, client, animal), 
      clientData, 
      events.isNewClient, 
      events.isNewAnimal,
      events.sendZoom, 
      events.sendCalendarEvent,
      events.sendEmail)
    
    logger.debug("Final eventConfirm data : ", eventConfirmData)
    CalendarUpdate(eventConfirmData).then((result) => {
        if (result.status === 200) {
          messageStore.sendMessage("Le workflow de la demande est démarré")
        } else {
          messageStore.sendMessage(result.data, "error", "Erreur lors de la création du workflow")
        }
      })
    
}


