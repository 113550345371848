import { formatToSystemDate } from "../../date/DateUtils"
import { v4 as uuid } from 'uuid';
import {  Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import { DeleteItem } from '../../common/DeleteItem';
import {  EditItemIconContent, InfoIconContent, TooltipIconButton } from '../../common/IconFactory';
import CheckIcon from '@mui/icons-material/Check';
import Products from '../../../api/Products';
import { useDispatch } from 'react-redux';
import { addLoadingCount, subLoadingCount } from '../../common/LoadingSlice';
import { useTranslation } from "react-i18next";
import InfoItemTooltip from "../../card/InfoItemTooltip";



export const CATEGORY_MAP = {"md": "Medication", "eq": "Equipment", "se": "Service", "ot": "Autre"}

export const emptyItem = {no: uuid(), isActif: true, name: "", category: "md", billingNameFr: "", billingNameEn: "", retailPrice: 1}
export const emptyItemDetail = {creationDate: formatToSystemDate(new Date()), updateDate: null, upc: "", din: "", incRxFee: false, fee: 0, unitPrice: 0, factor: 1.9, more: ""}



export const ProductView = ({row, onEditClick, handleDelete}) => {
    const [open, setOpen] = React.useState(false);
    const [detail, setDetail] = React.useState({});
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (open) {
        loadDetail(row.no)
      }
    }, [open])

    const loadDetail = (no) => {
      dispatch(addLoadingCount())
      return new Products().GetDetail(no).then(setDetail)
        .then(subLoadingCount)
        .then(dispatch)   
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell >{!row.isActif && <ClearIcon color='error'/>}</TableCell>
          <TableCell component="th" scope="row" align="left">
            {row.name}
          </TableCell>
          <TableCell align="left">{CATEGORY_MAP[row.category]}</TableCell>
          <TableCell align="right">{row.retailPrice} $</TableCell>
          <TableCell>
            <TooltipIconButton onClick={() => loadDetail(row.no).then(() => setOpen(false) & onEditClick(row, detail))}><EditItemIconContent /></TooltipIconButton>
            { (row.canDelete === undefined || row.canDelete === true) &&
              <DeleteItem displayOnlyIcon={true} handleDelete={() => handleDelete(row.no)}/>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <ProductDetailView product={row} detail={detail}/>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}



export const ProductDetailView = ({product, detail}) => {
    const [t] = useTranslation();
    return (<Box sx={{ margin: 1 }}>
        <Typography variant="h6" gutterBottom component="div">
          Détails
        </Typography>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>{t("product.item.billFr")}</TableCell>
              <TableCell>{t("product.item.billEn")}</TableCell>
              <TableCell align="right">{t("product.item.cost")}</TableCell>
              <TableCell align="right">{t("product.item.factor")}</TableCell>
              <TableCell >{t("product.item.rxFee")}</TableCell>
              <TableCell >{t("product.item.upc")}</TableCell>
              <TableCell >{t("product.item.din")}</TableCell>
              <TableCell >{t("product.item.more")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow key={product.no}>
                <TableCell component="th" scope="row">{product.billingNameFr}</TableCell>
                <TableCell component="th" scope="row">{product.billingNameEn}</TableCell>
                <TableCell align="right">{detail.unitPrice}</TableCell>
                <TableCell align="right">{(detail.unitPrice === 0 || detail.unitPrice === "0") ? "-" : Math.round(product.retailPrice / detail.unitPrice)}</TableCell>
                <TableCell align="center">{detail.incRxFee && <CheckIcon color='primary'/>}</TableCell>
                <TableCell>{detail.upc}</TableCell>
                <TableCell>{detail.din}</TableCell>
                <TableCell>
                  <Box>
                  <InfoItemTooltip
                    noName={t('product.item.no')} 
                    no={product.no} 
                    updateDate={detail.updateDate} 
                    creationDate={detail.creationDate} />
  
                  { detail.more && 
                    <TooltipIconButton
                      title={detail.more}>
                      <InfoIconContent color="primary" />
                    </TooltipIconButton>
                  }
                  </Box>
                </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </Box>)
  
  }
  