
import { format } from 'date-fns';
import { frCA } from 'date-fns/locale';

//TODO Fix that!!
//const OFFSET = "GMT-0500"

const ADD_HOUR_WITH_TZ = "T00:00:00.000-05:00"
const SYSTEM_DATE_FORMAT = "yyyy-MM-dd"
const SYSTEM_DATE_HOUR_FORMAT = "yyyy-MM-dd_HH-mm"

const TO_SYSTEM_DATE_HOUR_FORMAT = "YYYY-MM-DD_HH-mm"
const TO_SYSTEM_DATE_FORMAT = "YYYY-MM-DD"
const TO_DISPLAY_DATE_FORMAT = "dd MMMM yyyy"
const TO_DISPLAY_DATE_HOUR_FORMAT = "dd MMMM yyyy - HH:mm"


/*
function convertFromSystemDateHourFormat(date) {
    return  DateTime.fromFormat(date, SYSTEM_DATE_FORMAT)
}*/

function convertToSystemDateHourFormat(date) {
    if (date === undefined || date == null) {
        return ""
    }
    return  date.format(TO_SYSTEM_DATE_HOUR_FORMAT)
}

function convertToSystemDateFormat(date) {
    if (date === undefined || date == null) {
        return ""
    }
    return  date.format(TO_SYSTEM_DATE_FORMAT)
}

function formatToDisplayFormat(date) {
    if (date === undefined || date == null) {
        return ""
    }
    return  format(date, TO_DISPLAY_DATE_FORMAT, {locale: frCA})
}
function formatTimeToDisplayFormat(date) {
    if (date === undefined || date == null) {
        return ""
    }
    return  format(date, TO_DISPLAY_DATE_HOUR_FORMAT, {locale: frCA})
}
function formatToDisplayFormatFromString(date) {
    if (date === undefined || date == null) {
        return ""
    }
    return  formatToDisplayFormat(new Date(date + ADD_HOUR_WITH_TZ))
}

function formatToSystemDate(date) {
    if (date === undefined || date == null) {
        return ""
    }
    return  format(date, SYSTEM_DATE_FORMAT)
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
export {monthDiff, formatToDisplayFormatFromString, SYSTEM_DATE_FORMAT, SYSTEM_DATE_HOUR_FORMAT, convertToSystemDateHourFormat, convertToSystemDateFormat, formatToDisplayFormat, formatTimeToDisplayFormat,formatToSystemDate}