import React  from 'react';
import { Grid } from '@mui/material';
import { formatToDisplayFormatFromString, formatToSystemDate } from '../date/DateUtils';
import CheckIcon from '@mui/icons-material/Check';
import { GetPaiementEmail } from '../common/CommonSlice';

//TODO translate
const emptyFacture = {"isActif": true, 
    isPaid: false, 
    "date": formatToSystemDate(new Date()), 
    "animal": {},
    "items":[],
    "total":"", 
    "no": "",
    "link": {}}
const emptyItem = {"name": "", "quantite": "1", "unitPrice": "1", "totalPrice": "1"}




const extraSection = (bill, preference) => {
    return bill.isPaid ? GetIsPaidSection(bill) : GetIsNotPaidSection(bill, preference)
  }

const GetIsPaidSection = (bill) => {

return <>
    <Grid item xs={4} >Date de paiement : </Grid>
    <Grid item xs={6} >{formatToDisplayFormatFromString(bill.paidDate)}</Grid>
    <Grid item xs={10} color="red">
    <h2><CheckIcon fontSize='large'/> Payé</h2>
    </Grid>
</>
}

const GetIsNotPaidSection = (bill, preference) => {
    return (
      <Grid item xs={10} >
        <br/>
        <i>Merci de faire votre virement Interac à : <b>{GetPaiementEmail(preference)}</b></i>  
      </Grid>
      )
  }

export { extraSection, emptyFacture, emptyItem }