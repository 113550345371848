import React  from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { consultTypeMap } from '../events/BaseEvent';
import PropTypes from 'prop-types';

function EventsConfirmTable({items}) {
    
    return (
      <tr>
        <td colSpan="4">
          <b>Information sur la demande</b>
          <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
            <b>Raison de la demande : </b> 
            { items.event.consultWhy}
          </Typography>
          <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
            <b>Plus d&#39;information : </b> 
            { items.event.consultMore}
          </Typography>
          <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
            <b>Type de demande de consultation : </b> 
            {consultTypeMap[items.event.consultType]}
          </Typography>
          <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
            <b>Date demandé : </b> 
            {items.event.selectedDate}
          </Typography>

          <b>Gestion de la demande</b>
          <Typography color="text.secondary" align="left"  component={'span'} >
            <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
              <b>Date schédulé : </b> 
              {items.calendar.startDateDisplay}
            </Typography>
          
            <FormGroup sx={{ my: 2, mx: 2 }} disabled>
              <FormControlLabel disabled control={<Switch id="newClient"  checked={items.isNewClient} />} label="Nouveau client" />
              <FormControlLabel disabled control={<Switch id="newAnimal"  checked={items.isNewAnimal} />} label="Nouvel animal" />
              <FormControlLabel disabled control={<Switch id="zoomLink"   checked={items.sendZoomLink}/>} label="Générer un lien Zoom" />
              { items.calendar.zoomLink !== undefined &&
                <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
                  <b>Lien zoom : </b> 
                  <a target='_blank' href={items.calendar.zoomLink.link} rel="noreferrer"> {items.calendar.zoomLink.link}</a>
                </Typography>
              }
              <FormControlLabel disabled control={<Switch id="calendarLink" checked={items.sendCalendarEvent}/>} label="Générer un événement calendrier pour le client" />
              <FormControlLabel disabled control={<Switch id="sendEmail"    checked={items.sendEmail}/>} label="Envoyer un courriel avec les détails de la réservation" />

            </FormGroup>

            <Typography color="text.secondary" align="left"  component={'span'}>
              <br/>
              <b>Courrie généré : </b>
              <br/>
              <b>Sujet :</b> {items.calendar.summary}
              <br/>
              <b>Contenu : </b>
              <div dangerouslySetInnerHTML={{__html: items.calendar.description}} />
              <br/>
              { !items.sendEmail && <b>Ce courriel n&#39;a pas été envoyé.</b>}
            </Typography>

          </Typography>     
            </td>
        </tr>

    );
  }


  EventsConfirmTable.propTypes = {
    items: PropTypes.object.isRequired,
    isPrintMode: PropTypes.bool
  };
  
export default EventsConfirmTable