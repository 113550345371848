import React  from 'react';
import { useState }  from 'react';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import {  GetLicense } from "../common/CommonSlice";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { hasPermissionSignature } from '../../services/AccessHub';
import { Box } from '@mui/material';
import { SignatureSection } from '../common/Signature';
import { LoadHeader } from '../headers/HeaderLoader';

function Signature({isPrintMode, displayLicense}) {
    const { t } = useTranslation();
    const curHeader = useSelector(state => state.common.header)?.payload?.preference
    const canShowSign = hasPermissionSignature()
    const [showSignature, setShowSignature] = useState(canShowSign);
    const licenceNo = GetLicense(curHeader)
    return (
    <>
        <LoadHeader />
        <Grid sx={{ my: 1 }}  container={true} spacing={1} direction="row"  >
            <Typography sx={{ mx: 3 }} color="text.secondary">
                <br/>
                {t('signature.prefix')} &ensp; &ensp;
            </Typography>

            <Box  sx={{display: 'inline-flex', flexDirection: 'column', justifyContent: 'start', height: '55' }} >
                
                {showSignature && <SignatureSection /> }
                {!showSignature && <Box className='Signature'></Box> }
                {"_______________________  "}
            </Box>
         
            
             {displayLicense &&
                <Typography sx={{ my: 4}}  color="text.secondary">
                     &ensp; &ensp; &ensp; &ensp; &ensp; &ensp;{"    # Licence : "}{licenceNo}
                </Typography>
             }
            

            {(!isPrintMode && canShowSign) &&
                    <Tooltip title={t('signature.display')}>
                        <IconButton aria-label='btnSign'>
                            {showSignature && <VisibilityIcon onClick={() => setShowSignature(false)} /> }
                            {!showSignature && <VisibilityOffIcon onClick={() => setShowSignature(true)} /> }
                        </IconButton>
                    </Tooltip>
                }
        </Grid>
    </>)
}


export default Signature


