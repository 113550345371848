import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Tooltip, Typography } from '@mui/material';
import { StandardTextField } from './TextFieldStyle';
import KeyVal from './KeyVal';
import { useTranslation } from 'react-i18next';

const weightMap = { "lb": "Lb",
    "kg": "Kg"}

const LB_DATA = "lb"
const KG_DATA = "kg"
const LB_LABEL = "Lb"
const KG_LABEL = "Kg"

const CONVERT_MULTIPLICATOR = 2.205


function WeightInput({isEditMode, weight, weightType, onWeightChange, onWeightTypeChange, defaultWeightType}) {
    const { t } = useTranslation();
    const [curWeight, setCurWeight] = React.useState(weight);
    const [curWeightType, setCurWeightType] = React.useState( weightType ? weightType : defaultWeightType );
    const [tooltip, setTooltip] = React.useState(null);

    React.useEffect(() => {
        setupTooltip(curWeight, curWeightType);
      }, [curWeightType, curWeight]);

    const getWeightType = () => {
        return weightType ? weightType : defaultWeightType
    }

    const handleWeightTypeChange = (value) => {
        setCurWeightType(value)
        if (onWeightTypeChange) {
            onWeightTypeChange(value)
        }
    };

    const handleWeightChange = (value) => {
        setCurWeight(value)
        if (onWeightChange) {
            onWeightChange(value)
        }
    };

    const setupTooltip = (weight, weightType) => {
        if (weight && weight !== undefined && weight !== "") {
            const curWeightText = WeightText({weight: weight, weightType: weightType, displayWeightType: weightType})
            const convertWeightText = WeightText({weight: weight, weightType: weightType, displayWeightType: weightType === LB_DATA ? KG_DATA : LB_DATA})
            
            setTooltip(curWeightText + " => " + convertWeightText)
        }
    }

    const getTooltip = () => {
        if (!tooltip || tooltip === undefined || tooltip === "") {
            setupTooltip(weight, weightType)
        }

        return tooltip
    }

   return (
    <FormControl>
        <RadioGroup 
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"    
            onChange={(e) => handleWeightTypeChange(e.target.value)}
            value={getWeightType()}>
            <Tooltip title={getTooltip()}>
                <StandardTextField label={t("animal.item.weight")} sx={{ my: 2, mx: 2, minWidth: 125}}
                    variant={ 'standard'}
                    formcontrolprops={{ formNoValidate: true }}
                    disabled={!isEditMode} InputLabelProps={{ shrink: true }}
                    testid="txtWeight"
                    onChange={(e) => handleWeightChange(e.target.value)}
                    value={weight} type="number"
                    maxLength={5} 
                    slotProps={{
                        htmlInput: {
                            min:0.01,
                            max:250.00,
                            step:"0.01"
                        }
                    }}/>
            </Tooltip>
            { isEditMode ?
                <>
                    {Object.keys(weightMap).map((key, i) => (
                        <FormControlLabel key={i}  required disabled={!isEditMode} value={key} control={<Radio inputProps={{ 'aria-label': "opt" + key }} />} label={weightMap[key]} />
                    ))}
                </>
                : 
                <Tooltip title={getTooltip()}>
                    <Typography sx={{ my: 4}} component={'span'}>
                        {weightMap[getWeightType()]}
                    </Typography>
                </Tooltip>
                
            }
        </RadioGroup>
    </FormControl>  
    )
}

function WeightText({weight, weightType, displayWeightType}) {


    const getLabel = (weightType) => {
        return weightType === LB_DATA ? LB_LABEL : KG_LABEL
    }

    const convertToLb = (weight) => {
        return Number(weight * CONVERT_MULTIPLICATOR).toFixed(1)
    }
    const convertToKg = (weight) => {
        return Number(weight / CONVERT_MULTIPLICATOR).toFixed(1)
    }

    const getWeightDisplay = (weight, weightType, displayWeightType) => {
        const lblDisplay = getLabel(displayWeightType)
        if (weightType === displayWeightType) {
            return weight + " " + lblDisplay
        }
        if (displayWeightType === LB_DATA) {
            return convertToLb(weight) + " " + lblDisplay
        } else {
            return convertToKg(weight) + " " + lblDisplay
        }
    }

      return (getWeightDisplay(weight, weightType, displayWeightType))
}


export const WeightTextDisplay = ({weight, weightType, title}) => {
    const { t } = useTranslation();
    const useTitle = title ? title : t("animal.item.weight")
    const curWeightText = WeightText({weight: weight, weightType: weightType, displayWeightType: weightType})
    const convertWeightText = WeightText({weight: weight, weightType: weightType, displayWeightType: weightType === LB_DATA ? KG_DATA : LB_DATA})
    
    if (weight > 0) {
        return ( 
            <KeyVal label={useTitle} value={<>{curWeightText} ({convertWeightText})</>} />
        )
    }
    return (<></>)

}

export default WeightInput
export {WeightText, LB_DATA, KG_DATA, LB_LABEL, KG_LABEL}

