import { createSlice } from '@reduxjs/toolkit'
import { logger } from './Logger'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    header: null,
    limits: {},
    reloadLimits: true,
    products: [],
    reloadProducts: true,
    logo: null,
    signature: null
  },
  reducers: {
    setHeader: (state, headerData) => {
      logger.trace("store : setHeader", headerData)
      state.header = headerData
    },
    setLimits: (state, limitsData) => {
      logger.trace("store : setLimits", limitsData)
      state.limits = limitsData
      state.reloadLimits = false
    },
    askReloadLimits: (state) => {
      logger.trace("store : askReloadLimits")
      state.reloadLimits = true
    },
    setLogo: (state, logo) => {
      logger.trace("store : setLogo")
      state.logo = logo;
    },
    setSignature: (state, sign) => {
      logger.trace("store : setSignature")
      state.signature = sign;
    },
    setProducts: (state, productsData) => {
      logger.trace("store : setProducts", productsData)
      state.products = {...productsData,
        payload: productsData.payload && productsData.payload != "" ? productsData.payload : []
      }
      state.reloadProducts = false
    },
    askReloadProducts: (state) => {
      logger.trace("store : askReloadProducts")
      state.reloadProducts = true
    },
  }
})

export const GetWeightConf = (conf) => {
  return conf?.weight
}
export const GetWeightType = (conf, type) => {
  return GetWeightConf(conf) ? GetWeightConf(conf)[type] : null
}
export const GetPaiementEmail = (conf) => {
  return conf?.paiement_email
}
export const GetLicense = (conf) => {
  return conf?.license
}
// Action creators are generated for each case reducer function
export const { setHeader, setLogo, setSignature, setLimits, askReloadLimits, setProducts, askReloadProducts } = commonSlice.actions

export default commonSlice.reducer