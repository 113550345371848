import React from "react"
import { ClientIconContent } from "../common/IconFactory"
import { navigateTo } from "./ViewHelper"
import { clientUrl } from "../Navigator"
import { IconButton } from "@mui/material"

const ClientCard = (navigateAction, client) => {
  const navTo = () => {
    return navigateTo(navigateAction, clientUrl, 
      {
        view: "view",
        selectedClient: client}
    )
  }

  return {
      title: <IconButton onClick={() => navTo()}><ClientIconContent/>{client.name}</IconButton>,
      member: [{
        name: client.email,
        add: client.phone,
        image_url: 
          "https://github.com/LeeJams/LeeJams.github.io/blob/master/assets/img/user.jpg?raw=true"
      }]
    }
}

export default ClientCard